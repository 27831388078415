/* eslint-disable react-hooks/rules-of-hooks */
import {
  Stack,
  Flex,
  Text,
  Heading,
  SimpleGrid,
  Image,
  Divider,
  Container,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/react";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import { useEffect } from "react";
import { FaShoppingCart } from "react-icons/fa";
import { MdChevronRight } from "react-icons/md";
import { useQuery } from "react-query";
import { useCart } from "./cart/addToCart";

const PlantOperationsPage = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  const { addToCart } = useCart();
  const { data: data1 } = useQuery(["product1"], () => {
    return axios.get(`product/filter`, {
      params: {
        filter: "title",
        equal: "CPCS Red Trained Operator Card",
      },
    });
  });

  const { data: data2 } = useQuery(["product2"], () => {
    return axios.get(`product/filter`, {
      params: {
        filter: "title",
        equal: "Get a Plant Operations Blue Competent Operator Card",
      },
    });
  });

  const { data: data3 } = useQuery(["product3"], () => {
    return axios.get(`product/filter`, {
      params: {
        filter: "title",
        equal: "CPCS Blue Competent Operator Card Renewal",
      },
    });
  });

  // console.log(data2?.data);
  const handleAddToCart = (data) => {
    const { _id, name, price } = data?.data.result[0];
    addToCart({
      _id,
      name,
      price,
      quantity: 1,
      vat: data?.data.result[0].productCategory.vat,
    });
  };

  return (
    <div>
      <Container maxW={"90%"} py={12}>
        <Breadcrumb
          mb={5}
          fontSize={"xs"}
          fontWeight={600}
          spacing="4px"
          separator={<MdChevronRight color="gray.500" />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink href="/">Home</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href="/cpcs">CPCS</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
          <Stack spacing={4} data-aos="zoom-out">
            <Heading color={"#941C1C"}> CPCS Red Trained Operator Card</Heading>
            <Text fontSize={"md"}>
              <strong>
                The Plant Operations Red Trained Operator Card is issued on
                successful completion of the CPCS Theory and Practical Technical
                Tests which must be taken with a CPCS accredited test centre.
              </strong>
            </Text>
            <Text>
              <ul>
                To obtain a Red Trained Operator Card, you must complete the
                following steps in sequence:
                <li style={{ margin: "20px" }}>
                  Pass the relevant Health, Safety, and Environment test.
                </li>
                <li style={{ margin: "20px" }}>
                  Achieve the CPCS theory test (within two years of passing the
                  Health, Safety, and Environment test).
                </li>
                <li style={{ margin: "20px" }}>
                  Achieve the CPCS practical test (within six months of passing
                  the CPCS theory test and within two years of passing the
                  Health, Safety, and Environment test).
                </li>
              </ul>
              <Text>
                <strong>
                  These requirements ensure that operators are trained and
                  tested to operate machinery safely and efficiently.
                </strong>
              </Text>
            </Text>
            <Button
              onClick={() => handleAddToCart(data1)}
              textTransform={"uppercase"}
              bgColor={"#121212"}
              fontWeight={600}
              fontSize={"sm"}
              p={5}
              alignSelf={"flex-start"}
              color={"white"}
              borderRadius={"none"}
            >
              £{data1?.data.result[0].price} + VAT
              <FaShoppingCart />
            </Button>
          </Stack>
          <Flex data-aos="fade-left">
            <Image
              rounded={"md"}
              alt={"feature image"}
              src={require("../assests/cpcsPage/redcard.png")}
              objectFit={"contain"}
            />
          </Flex>
        </SimpleGrid>
      </Container>

      <Divider my={6} />

      <Container maxW={"90%"} py={12}>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
          <Stack spacing={4} data-aos="zoom-out">
            <Heading color={"#941C1C"}>
              Get a Plant Operations Blue Competent Operator Card
            </Heading>
            <Text fontSize={"lg"}>
              <strong>
                Details for product tab: Learn what achievements and experience
                you require in order to gain a Blue Competent Operator Card.
              </strong>
            </Text>
            <Text>
              <ul>
                To obtain a Blue Competent Operator Card, you must complete the
                following steps:
                <li style={{ margin: "20px" }}>
                  Provide proof of achievement of the VQ (Vocational
                  Qualification) for the category being applied for to the CPCS
                  Test Centre.
                </li>
                <li style={{ margin: "20px" }}>
                  Pass the relevant Health, Safety, and Environment Test.
                </li>
                <li style={{ margin: "20px" }}>
                  Achieve the CPCS Theory Test (within two years of passing the
                  Health, Safety, and Environment Test).
                </li>
                <li style={{ margin: "20px" }}>
                  Achieve the CPCS Practical Test (within six months of passing
                  the CPCS Theory Test and within two years of passing the
                  Health, Safety, and Environment Test).
                </li>
              </ul>
            </Text>
            <Text>
              Both the CPCS Theory and Practical Tests are conducted by
              accredited CPCS test centres. Once you pass the CPCS Practical
              Test, the accredited CPCS test centre will request the Competent
              Operator Card on your behalf.
            </Text>
            <Text>
              <strong>
                Upon receiving your first Competent Operator Card, you will also
                be sent a CPCS logbook. The logbook is designed to record your
                lifelong learning and evidence towards the renewal of your
                Competent Operator Card.
              </strong>
            </Text>
            <Button
              onClick={() => handleAddToCart(data2)}
              textTransform={"uppercase"}
              bgColor={"#121212"}
              fontWeight={600}
              fontSize={"sm"}
              color={"white"}
              borderRadius={"none"}
              // bg={("blue.50", "blue.900")}
              p={5}
              alignSelf={"flex-start"}
            >
              £{data2?.data.result[0].price} + VAT
              <FaShoppingCart />
            </Button>
          </Stack>
          <Flex data-aos="fade-left">
            <Image
              rounded={"md"}
              alt={"feature image"}
              src={require("../assests/cpcsPage/bluecard.png")}
              objectFit={"contain"}
            />
          </Flex>
        </SimpleGrid>
      </Container>

      <Divider my={6} />

      <Container maxW={"90%"} py={12}>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
          <Stack spacing={4} data-aos="zoom-out">
            <Heading color={"#941C1C"}>
              CPCS Blue Competent Operator Card Renewal
            </Heading>
            <Text fontSize={"lg"}>
              <strong>
                Details for product tab: Let us help you renew your CPCS Blue
                Competent Operator Card. Our team will make the process easy and
                hassle-free for you!
              </strong>
            </Text>
            <Text>
              <ul>
                To renew your CPCS Competent Operator Card, follow these steps:
                <li style={{ margin: "20px" }}>
                  Ensure you hold an in-date CPCS Blue Competent Operator Card
                  in the category or categories you wish to renew.
                </li>
                <li style={{ margin: "20px" }}>
                  Pass the relevant Health, Safety, and Environment Test within
                  the last two years (must be valid on receipt of the
                  application).
                </li>
                <li style={{ margin: "20px" }}>
                  Pass the CPCS Renewal Test relevant to the categories you are
                  renewing (must be valid on receipt of application).
                </li>
              </ul>

              <ul>
                <strong>
                  Demonstrate ongoing practical operating experience through one
                  of the following routes:
                </strong>
                <li style={{ margin: "20px" }}>Logbook</li>
                <li style={{ margin: "20px" }}>
                  On-Site Assessment with a registered test centre
                </li>
                <li style={{ margin: "20px" }}>
                  CPCS Practical Test with a registered test centre
                </li>
                <li style={{ margin: "20px" }}>
                  Vocational Qualification (VQ)*
                </li>
              </ul>
            </Text>
            <Text>
              <strong>
                It is important to renew your card at least 3 months before the
                expiry date to ensure continuity of your certification. Please
                note that Industry Accreditation (Grandfather Rights) is being
                phased out, and CPCS Competent Operator Cards obtained through
                this route will be completely removed by 31 December 2024. For
                more information, visit our website.
              </strong>
            </Text>
            <Button
              onClick={() => handleAddToCart(data3)}
              textTransform={"uppercase"}
              bgColor={"#121212"}
              fontWeight={600}
              fontSize={"sm"}
              // bg={("blue.50", "blue.900")}
              p={5}
              alignSelf={"flex-start"}
              color={"white"}
              borderRadius={"none"}
            >
              £{data3?.data.result[0].price} + VAT <FaShoppingCart />
            </Button>
          </Stack>
          <Flex data-aos="fade-left">
            <Image
              rounded={"md"}
              alt={"feature image"}
              src={require("../assests/cpcsPage/bluecard.png")}
              objectFit={"contain"}
            />
          </Flex>
        </SimpleGrid>
      </Container>

      <Divider my={6} />
    </div>
  );
};

export default PlantOperationsPage;
