import {
  Heading,
  Text,
  Link,
  VStack,
  Button,
  UnorderedList,
  ListItem,
  Icon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  useBreakpointValue,
  Flex,
} from "@chakra-ui/react";
import { FaShoppingCart } from "react-icons/fa";
import { MdOutlineFileDownload as Download } from "react-icons/md";
import React, { useState } from "react";
import { MdChevronRight } from "react-icons/md";
import ContactForm from "./course-details/contactForm";

function NVQLevel6Page() {
  const points = [
    "Remotely – ONLINE PORTFOLIO",
    "FAST TRACK COMPLETION 8-12 weeks",
    "Instalment payment plans available",
    "Leading to BLACK CSCS Card",
    "Access to MCIOB",
  ];
  const [name, setname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  return (
    <Flex
      direction={useBreakpointValue({ base: "column", md: "row" })}
      bg="white"
      p={useBreakpointValue({ base: 2, md: 4 })}
      justify="space-between"
      px={useBreakpointValue({ base: 6, md: "4rem" })}
      maxW="150ch"
      gap={useBreakpointValue({ base: 2, md: 5 })}
      mx="auto"
      position="relative"
      minHeight="100vh">
      <VStack
        spacing={4}
        align="stretch"
        w={useBreakpointValue({ base: "100%", md: "55%" })}
        px={useBreakpointValue({ base: 0, md: 10 })}
        my={useBreakpointValue({ base: 4, md: "3rem" })}>
        <Breadcrumb
          mb={5}
          fontSize="xs"
          fontWeight={500}
          spacing="4px"
          separator={<MdChevronRight color="gray.500" />}>
          <BreadcrumbItem>
            <BreadcrumbLink href="/">Home</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href="/nvq-level-6-construction">
              NVQ Level 6 construction
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Heading as="h1" size="lg" color="#941C1C" mb={3}>
          NVQ Level 6 Diploma in Construction Site Management
        </Heading>
        <UnorderedList
          style={{
            listStyle: "circle",
            color: "black",
            marginBottom: 15,
          }}>
          {points.map((point, index) => (
            <ListItem key={index}>
              <Text>{point}</Text>
            </ListItem>
          ))}
        </UnorderedList>
        <Text fontSize="xl" fontWeight="600">
          £1390.00 + VAT
        </Text>
        <Flex
          direction={useBreakpointValue({ base: "column", md: "row" })}
          justify={useBreakpointValue({ base: "center", md: "space-between" })}
          align="center"
          gap={useBreakpointValue({ base: 2, md: 5 })}>
          <Button
            w={useBreakpointValue({ base: "100%", md: "50%" })}
            borderRadius="2rem"
            color="white"
            bg="#941C1C"
            _hover={{ filter: "brightness(105%)" }}
            transition="all ease-in-out 200ms">
            <Icon as={FaShoppingCart} mr={3} />
            Book Now
          </Button>
          <Button
            w={useBreakpointValue({ base: "100%", md: "50%" })}
            borderRadius="2rem"
            color="#941C1C"
            border="1px solid #941C1C"
            bg="transparent"
            _hover={{ filter: "brightness(105%)" }}
            transition="all ease-in-out 200ms">
            <Icon as={Download} mr={3} />
            <Link
              href="https://www.proqualab.com/wp-content/uploads/2016/10/ProQual-L2-NVQ-Diploma-in-Decorative-Finishing-and-Industrial-Painting-Occs-July-2020.pdf"
              isExternal>
              Course Specifications
            </Link>
          </Button>
        </Flex>
        <VStack mt={6} textAlign="left" justify="start" align="start">
          <Text>
            The NVQ Level 6 in Construction Site Management is a
            proficiency-based qualification for current managers working on
            construction sites who want a recognized qualification to develop
            their professional career. This NVQ is aimed at site managers who
            handle responsibility for leading construction projects.
          </Text>

          <Text>
            NVQ Level 6 Construction Site Management is most appropriate for
            managers overseeing all aspects of construction management. You will
            be expected to collect a variety of evidence from your workplace
            which can prove your proficiency, including technical knowledge and
            producing technical plans for projects, as well as managing
            resources and overcoming problems. You must already have experience
            and knowledge of managing construction projects and teams of people.
          </Text>

          <Text fontWeight="bold">Entry Requirements</Text>
          <Text>
            You must be able to demonstrate that you can meet the skills,
            knowledge and experience requirements of the NVQ before enrollment.
          </Text>

          <Text fontWeight="bold">How long will my NVQ take to complete?</Text>
          <Text>
            An assessment plan will be agreed during your Initial
            assessment/Induction and a planned end date agreed. However, we
            would expect your qualification to be complete with 12 weeks
            depending on the learner's time availability.
          </Text>

          <Text fontWeight="bold">Assessment contents & units</Text>
          <Text>
            NVQ level 6 in Construction Site Management: Qualification Structure
          </Text>

          <Text>
            Mandatory Units
            <br />
            - Developing and Maintaining Good Occupational Working Relationships
            in the Workplace
            <br />
            - Allocating Work and Checking People’s Performance in the Workplace
            <br />- Establishing, Implementing and Maintaining Systems for
            Managing Health, Safety and Welfare in the Workplace
            <br />- Evaluating and Selecting Work Methods to Meet Project or
            Operational Requirements in the Workplace
            <br />- Monitoring Construction Related Project Activities in the
            Workplace
            <br />- Controlling Project Progress Against Agreed Quality
            Standards in the Workplace
            <br />
            -Controlling Project Progress Against Agreed Programmes in the
            Workplace
            <br />- Managing Personal Development in a Construction-Related
            Workplace
            <br />- Identifying and Enabling Learning Opportunities for Given
            Work Teams in the Workplace
            <br />- Contributing to the Identification of Work Teams in the
            Workplace
            <br />- Establishing, Controlling and Monitoring Environmental
            Factors and Sustainability in the Workplace
            <br />- Planning The Preparation of the Site for the Project or
            Operation in the Workplace
            <br />- Ensuring that Work Activities and Resources Meet Project
            Work Requirements in the Workplace
            <br />- Identifying, Allocating and Planning the Deployment and Use
            of Plant, Equipment or Machinery in the Workplace
            <br />- Organising, Controlling and Monitoring Supplies of Materials
            in the Workplace
            <br />- Establishing and Monitoring Communication Systems and
            Organisational Procedures in the Workplace
            <br />- Establishing Project Dimensional Control Criteria in the
            Workplace
            <br />- Controlling Project Quantities and Costs in the Workplace
            <br />- Evaluating Feedback Information and Recommending
            Improvements in the Workplace
          </Text>

          <Text fontWeight="bold">Industry recognition & benefits</Text>
          <Text>
            The NVQ L6 Diploma in Construction Site Management qualifies the
            holder to apply for the Construction Skills Certification Scheme
            (CSCS) black card
            <Link
              href="https://www.cscs.uk.com/card-type/manager/"
              color="blue.500"
              isExternal>
              CSCS Card
            </Link>
          </Text>
          <Text>
            A level 6 in Construction Site Management allows you access to
            becoming Chartered with MCIOB. Holders of a Level 6 NVQ will provide
            academic eligibility to undertake the full Industry Professional
            Review.
            <Link
              href="https://www.ciob.org/routes/i-have-nvq"
              color="blue.500"
              isExternal>
              CSCS Card
            </Link>
          </Text>
        </VStack>
      </VStack>
      <ContactForm />
    </Flex>
  );
}

export default NVQLevel6Page;
