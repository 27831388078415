import {
  Heading,
  Text,
  VStack,
  Button,
  UnorderedList,
  ListItem,
  Icon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  useBreakpointValue,
  Flex,
} from "@chakra-ui/react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Link as ChakraLink } from "@chakra-ui/react";
import { FaShoppingCart } from "react-icons/fa";
import { MdOutlineFileDownload as Download } from "react-icons/md";
import React from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { MdChevronRight } from "react-icons/md";
import AOS from "aos";
import ContactForm from "../contactForm";
import { useCart } from "../../../cart/addToCart";

function CourseDetailLevelSix2OnePage() {
  React.useEffect(() => {
    AOS.init({
      duration: 1200,
      easing: "ease-in-out-back",
    });
  }, []);
  const { addToCart } = useCart();

  const points = [
    "Remotely – ONLINE PORTFOLIO",
    "FAST TRACK COMPLETION 8-12 weeks",
    "Instalment payment plans available",
    "Leading to BLACK CSCS Card",
    "Access to MCIOB",
  ];
  const { isPending, error, data } = useQuery(["product"], () => {
    return axios.get(`product/filter`, {
      params: {
        filter: "detailsPage",
        equal: "/nvq-level-six-retrofit",
      },
    });
  });
  const navigate = useNavigate();
  const AddToCart = (data) => {
    const { _id, name, price } = data;
    addToCart(
      { _id, name, price, vat: data.productCategory.vat, quantity: 1,type: data.productCategory.type, },
      0
    );
    navigate("/checkout");
  };
  return (
    <Flex
      direction={useBreakpointValue({ base: "column", md: "row" })}
      bg="white"
      p={useBreakpointValue({ base: 2, md: 4 })}
      justify="space-between"
      px={useBreakpointValue({ base: 6, md: "4rem" })}
      maxW="150ch"
      gap={useBreakpointValue({ base: 2, md: 5 })}
      mx="auto"
      position="relative"
      minHeight="100vh">
      <VStack
        spacing={4}
        align="stretch"
        w={useBreakpointValue({ base: "100%", md: "55%" })}
        px={useBreakpointValue({ base: 0, md: 10 })}
        my={useBreakpointValue({ base: 4, md: "3rem" })}>
        <Breadcrumb
          mb={5}
          fontSize="xs"
          fontWeight={500}
          spacing="4px"
          separator={<MdChevronRight color="gray.500" />}>
          <BreadcrumbItem>
            <BreadcrumbLink href="/">Home</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href="/level-six-page">NVQ Level 6</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href="/nvq-level-six-retrofit">
              Construction Site Management
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Heading as="h1" size="lg" color="#941C1C" mb={3}>
          NVQ Level 6 Diploma in Construction Site Management Retrofit{" "}
        </Heading>
        <UnorderedList
          style={{
            listStyle: "circle",
            color: "black",
            marginBottom: 15,
          }}>
          {points.map((point, index) => (
            <ListItem key={index}>
              <Text>{point}</Text>
            </ListItem>
          ))}
        </UnorderedList>
        <Text fontSize="xl" fontWeight="600">
          £{data?.data.result[0].price} + VAT
        </Text>
        <Flex
          direction={useBreakpointValue({ base: "column", md: "row" })}
          justify="space-between"
          align="center"
          gap={useBreakpointValue({ base: 2, md: 5 })}>
          <Button
            onClick={() => AddToCart(data?.data.result[0])}
            w={useBreakpointValue({ base: "100%", md: "50%" })}
            borderRadius="2rem"
            color="white"
            bg="#941C1C"
            _hover={{ filter: "brightness(105%)" }}
            transition="all ease-in-out 200ms">
            <Icon as={FaShoppingCart} mr={3} />
            Book Now
          </Button>
          <Button
            w={useBreakpointValue({ base: "100%", md: "50%" })}
            borderRadius="2rem"
            color="#941C1C"
            border="1px solid #941C1C"
            bg="transparent"
            _hover={{ filter: "brightness(105%)" }}
            transition="all ease-in-out 200ms">
            <Icon as={Download} mr={3} />
            <ChakraLink
              href="https://www.proqualab.com/wp-content/uploads/2016/10/ProQual-Level-6-NVQ-Diploma-in-Construction-Site-Management-2.pdf"
              isExternal>
              Course Specifications
            </ChakraLink>
          </Button>
        </Flex>
        <VStack mt={6} textAlign="left" justify="start" align="start">
          <Text fontWeight="bold">About this NVQ assessment</Text>
          <Text>
            The NVQ Level 6 in Construction Site Management is a
            proficiency-based qualification for current managers working on
            construction sites who want a recognised qualification to develop
            their professional career. This NVQ is aimed at site managers who
            handle responsibility for leading construction projects NVQ Level 6
            Construction Site Management is most appropriate for managers
            overseeing all aspects of construction management. You will be
            expected to collect a variety of evidence from your workplace which
            can prove your proficiency, including technical knowledge and
            producing technical plans for projects, as well as managing
            resources and overcoming problems. You must already have experience
            and knowledge of managing construction projects and teams of people.
          </Text>

          <Text fontWeight="bold">Entry Requirements</Text>
          <Text>
            You must be able to demonstrate that you can meet the skills,
            knowledge and experience requirements of the NVQ before enrolment.
          </Text>
          <Text fontWeight="bold">How long will my NVQ take to complete?</Text>
          <Text>
            An assessment plan will be agreed during your Initial
            assessment/Induction and a planned end date agreed. However, we
            would expect your qualification to be complete with 12 weeks
            depending on the learners time availability.
          </Text>
          <Text fontWeight="bold">Assessment contents & units</Text>
          <Text>
            NVQ level 6 in Construction Site Management: Qualification Structure
          </Text>
          <Text>
            Mandatory Units:
            <br />
            1. Developing and maintaining good occupational working
            relationships in the workplace
            <br />
            2. Allocating work and monitoring people's performance in the
            workplace
            <br />
            3. Contributing to the identification of a work team in the
            workplace
            <br />
            4. Establishing, implementing and maintaining organisational systems
            for managing health, safety, welfare and wellbeing in the workplace
            <br />
            5. Establishing, controlling and monitoring environmental factors
            and sustainability in the workplace
            <br />
            6. Evaluating and confirming work methods in the workplace
            <br />
            7. Planning the preparation of the site for the project in the
            workplace
            <br />
            8. Monitoring project activities in the workplace
            <br />
            9. Ensuring that work activities and resources meet project work
            requirements in the workplace
            <br />
            10. Organising, controlling and monitoring supplies of materials in
            the workplace
            <br />
            11. Identifying and maintaining communication systems and
            organisational procedures in the workplace
            <br />
            11. Controlling project progress against agreed quality standards in
            the workplace
            <br />
            12. Controlling project progress against agreed programmes in the
            workplace
            <br />
            13. Managing your personal development in the workplace
            <br />
            14. Identifying, allocating and planning the deployment and use of
            plant, equipment or machinery in the workplace
            <br />
            15. Establishing dimensional control criteria in the workplace
            <br />
            16. Controlling project quantities and costs in the workplace
            <br />
            17. Evaluating feedback and making recommendations in the workplace
            <br />
            18. Managing the installation, maintenance, monitoring and removal
            of temporary works in the workplace
          </Text>

          <Text fontWeight="bold">Qualification Structure </Text>
          <Text>
            To achieve the NVQ Level 6 Diploma in Senior Site Inspection,
            candidates must complete a minimum of 8 units. This includes:
          </Text>

          <Text>
            Mandatory Units: <br />
            - Establishing, implementing, and maintaining systems for managing
            health and safety
            <br />
            - Evaluating and confirming work meets specifications
            <br />- Developing and maintaining working relationships
            <br />- Monitoring project quality and progress
            <br />- Identifying, assessing, and controlling environmental
            factors
            <br />- Coordinating preparation and review of risk assessments and
            method statements
          </Text>

          <Text>
            Optional Units: <br />
            - Managing project control systems
            <br />
            - Organizing project logistics
            <br />- Resolving technical construction problems
          </Text>

          <Text fontWeight="bold">Industry recognition & benefits</Text>
          <Text>
            The NVQ L6 Diploma in Construction Site Management qualifies the
            holder to apply for the Construction Skills Certification Scheme
            (CSCS)
            <ChakraLink
              as={RouterLink}
              to={"/detail-page-6"}
              style={{ color: "blue", fontWeight: "bold" }}>
              Black CSCS Card
            </ChakraLink>
          </Text>
          <Text>
            A level 6 in Construction Site Management allows you access to
            becoming Chartered with MCIOB. Holders of a Level 6 NVQ will provide
            academic eligibility to undertake the full Industry
            <ChakraLink
              as={RouterLink}
              href="https://www.ciob.org/"
              color="blue.500"
              isExternal>
              Professional Review.
            </ChakraLink>
          </Text>
        </VStack>
      </VStack>
      <ContactForm />
    </Flex>
  );
}

export default CourseDetailLevelSix2OnePage;
