import {
  Box,
  Button,
  Checkbox,
  Divider,
  Heading,
  Link,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { useMutation } from "react-query";
import { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_KEY } from "../../config/serverApiConfig";
import currency from "currency.js";

const PaymentPage = () => {
  const [cartItems, setCartItems] = useState([]);
  const [client, setClient] = useState();
  const [isChecked, setIsChecked] = useState(false);
  const toast = useToast();

  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem("cart")) || [];
    if (storedCart && Array.isArray(storedCart)) {
      const processedItems = storedCart.map((item) => {
        const price = currency(item.price, { symbol: "£" });
        const total = price
          .multiply(item.quantity)
          .add(price.multiply(item.vat / 100));
        return { ...item, total }; // Add total to each item
      });
      setCartItems(processedItems);
    }
    const clientData = JSON.parse(localStorage.getItem("client")) || [];
    setClient(clientData);
  }, []);

  const createPayment = async (formData) => {
    if (paymentType === "paypal") {
      const pay = await axios.post(
        "/checkout/paypal-create-checkout-session",
        formData
      );
      // console.log(pay);
      if (pay.data.href) {
        toast({
          title: "Payment Processing",
          description: "Your payment process please wait...",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        window.location.href = pay.data.href;
      } else {
        toast({
          title: "Payment Failed",
          description: "Please try again",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } else if (paymentType === "stripe") {
      const stripe = await loadStripe(STRIPE_KEY);
      const response = await axios.post(
        "/checkout/create-checkout-session",
        formData
      );

      const session = response.data;

      if (typeof session.id === "string") {
        toast({
          title: "Payment Successful",
          description: "Your payment process please wait...",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Payment Failed",
          description: "Please try again",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });
    }
  };

  const { isLoading, isError, error, data, mutate } =
    useMutation(createPayment);

  const [paymentType, setPaymentType] = useState("");
  // Payment method
  const paymentSubmit = async (e) => {
    e.preventDefault();

    setPaymentType(e.target.value);
    if (!isChecked) {
      toast({
        title: "Error",
        description: "Please agree to the terms and conditions.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    try {
      const ids = JSON.parse(localStorage.getItem("ids")) || [];
      let paymentBody = {};
      paymentBody["client"] = client._id;
      paymentBody["products"] = [];
      paymentBody["ids"] = [];

      cartItems.forEach((item) => {
        paymentBody["products"].push({
          _id: item._id,
          itemName: item.name,
          price: item.price,
          quantity: item.quantity,
          total: item.total,
          description: item.name,
          vat: item.vat ? item.vat : 0,
          discount: item.discount || 0,
        });
      });
      paymentBody["ids"] = ids;
      await mutate(paymentBody);
    } catch (error) {
      console.error(error);
    }
  };

  const calculateSubtotal = () => {
    return cartItems.reduce((totalPrice, item) => {
      const total = currency(currency(item.price).multiply(item.vat / 100), {
        symbol: "£",
      })
        .add(item.price)
        .multiply(item.quantity);
      return (totalPrice = currency(totalPrice, { symbol: "£" })
        .add(total)
        .format());
    }, 0);
  };

  const calculateTotal = () => {
    return calculateSubtotal();
  };

  return (
    <Box p={4}>
      <Box
        p={4}
        maxW={{ base: "100%", md: "500px" }}
        mx="auto"
        boxShadow="md"
        borderRadius="md"
        bg="white"
      >
        <VStack spacing={4} align="stretch">
          <Heading as="h2" size="md">
            Your Order
          </Heading>
          {cartItems.map((item, index) => (
            <Text key={index}>
              {item.name}:{" "}
              {currency(currency(item.price).multiply(item.vat / 100), {
                symbol: "£",
              })
                .add(item.price)
                .multiply(item.quantity)
                .format()}
            </Text>
          ))}
          <Text>Subtotal: {calculateSubtotal()}</Text>
          <Text>Total: {calculateTotal()}</Text>
          <Divider />
          <Text>
            Your personal data will be used to process your order, support your
            experience throughout this website, and for other purposes described
            in our{" "}
            <Link to="/privacy-policy" color="blue">
              Privacy Policy
            </Link>
          </Text>
          <Checkbox
            isChecked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
          >
            I have read and agree to the website{" "}
            <Link to="/privacy-terms" color="blue">
              terms and conditions
            </Link>
            *
          </Checkbox>
          <Button
            bgColor="#941C1C"
            color="white"
            borderRadius="lg"
            _hover={{ bgColor: "#6B7280" }}
            onClick={(e) => {
              paymentSubmit(e);
            }}
            value={"stripe"}
          >
            Pay with Card
          </Button>

          <Button
            bgColor="#0b2447"
            color="white"
            borderRadius="lg"
            _hover={{ bgColor: "#6B7280" }}
            onClick={(e) => {
              paymentSubmit(e);
            }}
            value={"paypal"}
          >
            Paypal
          </Button>
        </VStack>
      </Box>
    </Box>
  );
};

export default PaymentPage;
