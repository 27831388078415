import { useState, useEffect, useRef } from "react";
import {
  Box,
  Flex,
  Text,
  IconButton,
  Stack,
  Collapse,
  Icon,
  useColorModeValue,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Image,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  CloseIcon,
  SearchIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import { Link, useNavigate } from "react-router-dom";
import { FaShoppingCart } from "react-icons/fa";
import { useCart } from "../pages/cart/addToCart";
import { Empty, Select as SelectAnt } from "antd";
import useDebounce from "../hooks/useDebounce";
import { useMutation } from "react-query";
import axios from "axios";
import { SearchOutlined } from "@ant-design/icons";

export default function NavBar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const { cartCount } = useCart();
  const [itemCount, setItemCount] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const updateCartCount = () => {
    const cartItems = JSON.parse(localStorage.getItem("cart")) || [];
    const totalItemsInCart = cartItems.reduce(
      (total, item) => total + item.quantity,
      0
    );
    setItemCount(totalItemsInCart);
  };

  useEffect(() => {
    updateCartCount(); // Update cart count when component mounts
  }, []);
  const navbarTextColor = isScrolled ? "white" : "black";
  const navbarBgColor = isScrolled ? "#212121" : "white";
  const logo = isScrolled ? "./64X64.png" : "./64X64.png";

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  // search
  const isSearching = useRef(false);
  const [searching, setSearching] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [debouncedValue, setDebouncedValue] = useState("");

  const [selectOptions, setOptions] = useState([]);
  const [currentValue, setCurrentValue] = useState(undefined);
  const [, cancel] = useDebounce(
    () => {
      setDebouncedValue(searchTerm);
    },
    500,
    [searchTerm]
  );
  const searchProduct = async ({ entity, options = {} }) => {
    if (entity !== undefined) {
      let query = "?";
      for (let key in options) {
        query += `${key}=${options[key]}&`;
      }
      query = query.slice(0, -1);
      const response = await axios.get(entity + "/search" + query);

      const data = response.data;
      setSearchResult(data?.result);
      mutate(data);
    }
  };

  useEffect(() => {
    if (debouncedValue !== "") {
      const options = {
        q: debouncedValue,
        fields: ["name", "description", "title"],
      };
      searchProduct({ entity: "product", options });
    }
    return () => {
      cancel();
    };
  }, [debouncedValue]);

  const { isLoading, isSuccess, error, mutate } = useMutation(searchProduct);
  const onSearch = (searchText) => {
    if (searchText && searchText !== "") {
      isSearching.current = true;
      setSearching(true);
      setOptions([]);
      setCurrentValue(undefined);
      setSearchTerm(searchText);
    }
  };
  useEffect(() => {
    if (isSearching.current) {
      if (isSuccess) {
        setOptions(searchResult);
      } else {
        setSearching(false);
        setCurrentValue(undefined);
        setOptions([]);
      }
    }
  }, [isSuccess, searchResult]);

  const outputValue = "name";
  const [state, setState] = useState([0]);
  const onRerender = () => {
    setState([state + 1]);
  };
  const navigate = useNavigate();
  const onSelect = (data) => {
    const currentItem = searchResult.find((item) => {
      return item[outputValue] === data;
    });
    if (currentItem) {
      setSelectedItem(currentItem);
      setSelectedIndex(searchResult.indexOf(currentItem));
    }
    navigate(data);
    onRerender();
  };
  const displayLabels = ["name"];

  const labels = (optionField) => {
    return displayLabels.map((x) => optionField[x]).join(" ");
  };

  return (
    <Box position={"sticky"} top={7} zIndex={"sticky"}>
      <Flex
        bg={navbarBgColor}
        color={navbarTextColor}
        h={"50px"}
        py={{ base: 2 }}
        px={{ base: 4 }}
        align={"center"}
        justify="space-between">
        <Flex
          flex={{ base: 1, md: "auto" }}
          ml={{ base: -2 }}
          display={{ base: "flex", md: "none" }}>
          <IconButton
            bgColor={navbarBgColor}
            color={navbarTextColor}
            onClick={handleToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            aria-label={"Toggle Navigation"}
          />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: "start", md: "start" }}>
          <Link to={"/"}>
            <Image src={logo} alt="avatar" rounded="full" />
          </Link>
          <Flex display={{ base: "none", md: "flex" }} ml={5}>
            <DesktopNav />
          </Flex>
        </Flex>
        <Flex>
          <Collapse in={!isOpen} animateOpacity>
            <SelectAnt
              loading={isSuccess}
              showSearch
              allowClear
              placeholder={
                <SearchOutlined style={{ float: "right", padding: "8px 0" }} />
              }
              defaultActiveFirstOption={false}
              filterOption={false}
              notFoundContent={searching ? "... Searching" : <Empty />}
              value={currentValue}
              onSearch={onSearch}
              style={{ width: "200px" }}
              onSelect={onSelect}>
              {selectOptions.map((optionField) => (
                <SelectAnt.Option
                  key={optionField[outputValue]}
                  value={optionField.detailsPage}>
                  {labels(optionField)}
                </SelectAnt.Option>
              ))}
            </SelectAnt>
          </Collapse>

          <Link to={"/checkout"}>
            <IconButton
              aria-label="Cart"
              color={navbarTextColor}
              background={"none"}
              icon={<FaShoppingCart />} // Use your cart icon here
              mr={4}
              borderRadius={"50%"}
            />
            {cartCount ? ( // Render cart count if it's greater than 0
              <Box
                bg="red"
                color="white"
                borderRadius="50%"
                fontSize="0.8em"
                fontWeight="bold"
                position={"fixed"}
                top={7}
                right={7}
                px={2}
                py={1}>
                {cartCount > 9 ? "9+" : cartCount}
              </Box>
            ) : (
              // Render cart count if it's greater than 0
              <Box
                bg="red"
                color="white"
                borderRadius="50%"
                fontSize="0.8em"
                fontWeight="bold"
                position={"fixed"}
                top={7}
                right={7}
                px={2}
                py={1}>
                {itemCount > 9 ? "9+" : itemCount}
              </Box>
            )}
          </Link>
        </Flex>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
}

const DesktopNav = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarTextColor = isScrolled ? "white" : "black";
  const navbarBgColor = isScrolled ? "#212121" : "white";
  const hoverTextColor = isScrolled ? "#FDE767" : "#941C1C";
  return (
    <Stack direction={"row"} spacing={4} p={"20px 0 20px 0"}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              <Box
                as="a"
                p={2}
                href={navItem.href}
                fontSize={"md"}
                fontWeight={500}
                color={navbarTextColor}
                _hover={{
                  textDecoration: "none",
                  color: hoverTextColor,
                }}>
                {navItem.label}
              </Box>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={"xl"}
                width={"200px"}
                p={2}
                rounded={"lg"}
                cursor={"pointer"}>
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, children, href }) => {
  return (
    <Popover trigger="hover" placement="right-start">
      <PopoverTrigger>
        <Flex
          as="a"
          href={href ? href : "#"}
          p={2}
          fontSize="sm"
          fontWeight={500}
          color={"black"}
          transition={"all ease-in-out 200ms"}
          _hover={{
            textDecoration: "none",
            bg: "#941C1C",
            rounded: "md",
            color: "#FFFFFF",
          }}
          align="center">
          <Box>{label}</Box>
          <ChevronRightIcon ml={2} />
        </Flex>
      </PopoverTrigger>
      {typeof children === "object" && (
        <PopoverContent
          width={"200px"}
          border={0}
          boxShadow={"lg"}
          p={2}
          rounded={"md"}
          ml={2}>
          <Stack>
            {children.map((child) => (
              <Box
                key={child.label}
                as="a"
                color={"black"}
                href={child.href}
                p={2}
                transition={"all ease-in-out 200ms"}
                _hover={{
                  textDecoration: "none",
                  bg: "#941C1C",
                  rounded: "md",
                  color: "#FFFFFF",
                }}>
                {child.label}
              </Box>
            ))}
          </Stack>
        </PopoverContent>
      )}
    </Popover>
  );
};

const MobileNav = () => {
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={4}
      display={{ md: "none" }}>
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }) => {
  const [isOpen, setIsOpen] = useState(false);
  const hasChildren = children && children.length > 0;

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Stack spacing={0}>
      <Box
        py={2}
        as="a"
        href={href ?? "#"}
        alignItems="center"
        _hover={{
          textDecoration: "none",
        }}
        onClick={() => hasChildren && handleToggle()}>
        <Flex
          justify="space-between"
          align="center"
          px={4}
          py={3}
          w="100%"
          fontWeight={600}
          color={"black"}
          cursor={hasChildren ? "pointer" : "default"}
          transition="background-color 0.3s"
          _hover={{
            bg: useColorModeValue("gray.100"),
          }}>
          <Text>{label}</Text>
          {hasChildren && (
            <Icon
              as={ChevronDownIcon}
              w={6}
              h={6}
              transform={isOpen ? "rotate(180deg)" : ""}
              transition="transform 0.2s"
            />
          )}
        </Flex>
      </Box>

      {hasChildren && (
        <Collapse in={isOpen}>
          <Stack
            pl={0}
            borderLeft={1}
            borderStyle="solid"
            borderColor={"gray.700"}
            borderRadius="0 0 6px 6px"
            bg={"gray.300"}>
            {children.map((child) => (
              <MobileNavItem key={child.label} {...child} />
            ))}
          </Stack>
        </Collapse>
      )}
    </Stack>
  );
};

const NAV_ITEMS = [
  {
    label: "NVQ Courses",
    children: [
      {
        label: "NVQ's Construction",
        children: [
          {
            label: "Level 1 Award Health & Safety",
            href: "/level-one-page",
          },
          {
            label: "NVQ Level 2",
            href: "/level-two-page",
          },
          {
            label: "NVQ Level 3",
            href: "/level-three-page",
          },
          {
            label: "NVQ Level 4",
            href: "/level-four-page",
          },
          {
            label: "NVQ Level 6",
            href: "/level-six-page",
          },
          {
            label: "NVQ Level 7",
            href: "/level-seven-page",
          },
        ],
      },
      {
        label: "NVQs Health and Safety",
        href: "/nvq-health-safety",
      },
    ],
  },
  {
    label: "CITB Courses",
    href: "/citb-courses",
  },
  {
    label: "CSCS Cards",
    children: [
      {
        label: "Apply for CSCS Cards",
        href: "/cscs-cards",
      },
      {
        label: "CITB Health and Safety test",
        href: "/citb-health-and-safety-policy",
      },
    ],
  },
  {
    label: "Plant Operations",
    href: "/plant-operations",
  },
  {
    label: "Contact",
    href: "/contact-us",
  },
  {
    label: "About Us",
    href: "/about-us",
  },
];
