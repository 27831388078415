import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Input,
  Button,
  VStack,
  Text,
  Flex,
  IconButton,
  Image,
  Spacer,
} from "@chakra-ui/react";
import { MdDelete } from "react-icons/md";
import { FaPlus, FaMinus } from "react-icons/fa6";
import { Link } from "react-router-dom";
import currency from "currency.js";
import { useCart } from "./addToCart";

const EmptyCartMessage = () => (
  <VStack spacing={4} alignItems="center">
    <Image
      src={require("../../assests/cartPage/cart1.webp")}
      alt="Empty Cart"
      maxW="200px"
    />
    <Text fontSize="lg" fontWeight="bold" color="gray.500" textAlign="center">
      Your cart is empty!
    </Text>
    <Text fontSize="md" color="gray.500" textAlign="center">
      Add some items to your cart to continue shopping.
    </Text>
  </VStack>
);

const CartPage = () => {
  const [cart, setCart] = useState([]);
  const { removeFromCart, quantityChange } = useCart();

  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem("cart")) || [];
    if (storedCart && Array.isArray(storedCart)) {
      const processedItems = storedCart.map((item) => {
        const price = currency(item.price, { symbol: "£" });
        const total = price
          .multiply(item.quantity)
          .add(price.multiply(item.vat / 100));
        return { ...item, total }; // Add total to each item
      });
      setCart(processedItems);
    }
  }, []);

  const handleDelete = (index) => {
    removeFromCart(index);
    const newCart = [...cart];
    newCart.splice(index, 1);
    setCart(newCart);
    localStorage.setItem("cart", JSON.stringify(newCart));
  };

  const handleQuantityChange = (index, value) => {
    quantityChange(index, value);
    const newCart = [...cart];
    newCart[index].quantity = Math.max(1, value);
    setCart(newCart);
    localStorage.setItem("cart", JSON.stringify(newCart));
  };

  const calculateTotalCount = () => {
    return cart.reduce((total, item) => total + item.quantity, 0);
  };

  const calculateTotalPrice = () => {
    return cart.reduce((totalPrice, item) => {
      const total = currency(currency(item.price).multiply(item.vat / 100), {
        symbol: "£",
      })
        .add(item.price)
        .multiply(item.quantity);
      return (totalPrice = currency(totalPrice, { symbol: "£" }).add(total));
    }, 0);
  };

  return (
    <Box p={[2, 4]} mx="auto" maxWidth="700px">
      <Heading fontSize="lg" mb={4} color="#941C1C" textAlign="center">
        Your Cart
      </Heading>
      <VStack spacing={4} align="stretch">
        {cart.length === 0 ? (
          <EmptyCartMessage />
        ) : (
          cart.map((item, index) => (
            <Box key={index} p={4} borderBottomWidth="2px" borderRadius="md">
              <Flex justifyContent="space-between" alignItems="center">
                <Text fontSize="md" fontWeight={"bold"}>
                  {item.name}
                </Text>
                <IconButton
                  icon={<MdDelete />}
                  onClick={() => handleDelete(index)}
                  aria-label="Delete Product"
                  variant="ghost"
                  colorScheme="red"
                  size="sm"
                />
              </Flex>
              <Flex alignItems="center" mt={2}>
                <IconButton
                  icon={<FaMinus />}
                  onClick={() =>
                    handleQuantityChange(index, Math.max(1, item.quantity - 1))
                  }
                  aria-label="Decrease Quantity"
                  variant="ghost"
                  colorScheme="black"
                  size="sm"
                />
                <Input
                  type="number"
                  min={1}
                  value={item.quantity}
                  onChange={(e) =>
                    handleQuantityChange(index, parseInt(e.target.value))
                  }
                  w="50px"
                  mx={2}
                />
                <IconButton
                  icon={<FaPlus />}
                  onClick={() => handleQuantityChange(index, item.quantity + 1)}
                  aria-label="Increase Quantity"
                  variant="ghost"
                  colorScheme="black"
                  size="sm"
                />
              </Flex>
              <Text fontSize="sm" mt={2}>
                Price: {currency(item.price, { symbol: "£" }).format()} + (VAT:{" "}
                {item.vat}%)
              </Text>
              <Text fontSize="sm" fontWeight={"bold"}>
                Total:{" "}
                {currency(currency(item.price).multiply(item.vat / 100), {
                  symbol: "£",
                })
                  .add(item.price)
                  .multiply(item.quantity)
                  .format()}
              </Text>
            </Box>
          ))
        )}
        {cart.length > 0 && (
          <Box>
            <Text fontSize="md" mt={4}>
              Total Count: {calculateTotalCount()}
            </Text>
            <Text fontSize="md" fontWeight={"bold"} mt={2}>
              Total Price: {calculateTotalPrice().format()}
            </Text>
            <Spacer />
            <Link to="/checkout">
              <Button
                mt={4}
                bgColor="#941C1C"
                color="white"
                borderRadius="none"
                _hover={{ bgColor: "#6B7280" }}>
                Proceed to Checkout
              </Button>
            </Link>
          </Box>
        )}
      </VStack>
    </Box>
  );
};

export default CartPage;
