import { Box, Heading, Text } from "@chakra-ui/layout";
import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const PaymentFailed = () => {
  const navigate = useNavigate();
  localStorage.removeItem("ids");
  const handleBackToHome = () => {
    navigate("/");
  };
  return (
    <div>
      <Box textAlign="center" mt="20">
        <Heading color="red.500" mb="4">
          Payment Failed
        </Heading>
        <Text fontSize="xl">Oops! Something went wrong with your payment.</Text>
        <Button colorScheme="green" onClick={handleBackToHome}>
          Back to Home
        </Button>
      </Box>
    </div>
  );
};

export default PaymentFailed;
