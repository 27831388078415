import {
  Container,
  Heading,
  Text,
  Divider,
  ListItem,
  List,
} from "@chakra-ui/react";

export default function TermsAndConditionsPage() {
  return (
    <Container maxW="full" pt={4} px={4}>
      <Heading as="h1" fontSize="2xl" mb={6}>
        Terms and Conditions
      </Heading>
      <Text fontSize="md" mb={6}>
        <strong>Terms of Business of M2HSE Training LTD</strong>
      </Text>

      <Text fontSize="md" mb={6}>
        Welcome to our website. This page along with our Privacy Policy, Cookie
        Policy and Term of Website Use provides you information about us and the
        legal terms and conditions (Terms) on which we sell our online courses
        (Course(s)) as listed on our website (our site) to you.
      </Text>

      <Text fontSize="md" mb={6}>
        These Terms will apply to any contract between us for the sale of a
        Course to you (Contract). Please read these Terms carefully and make
        sure that you understand them, before ordering any Course from our site.
        Please note that before placing an order you will be asked to agree to
        these Terms. If you refuse to accept these Terms, you will not be able
        to order any Course from our site.
      </Text>

      <Text fontSize="md" mb={6}>
        You should print a copy of these Terms or save them to your computer for
        future reference.
      </Text>

      <Text fontSize="md" mb={6}>
        We amend these Terms from time to time as set out in clause 5. Every
        time you wish to order Course, please check these Terms to ensure you
        understand the terms which will apply at that time. These Terms were
        most recently updated on 19th October 2023.
      </Text>

      <Text fontSize="md" mb={6}>
        These Terms, and any Contract between us, are only in the English
        language.
      </Text>

      <Divider my={8} />

      <Heading as="h2" fontSize="xl" mb={4}>
        Information about Us
      </Heading>

      <Text fontSize="md" mb={6}>
        1.1. We operate the website www.M2HSE.co.uk. We are M2HSE Training LTD,
        a company registered in England and Wales under company number 12804208
        and with our registered office at 11 Humphries Close, RM9 5BX. Our VAT
        number is 357511104.
      </Text>

      <Text fontSize="md" mb={6}>
        1.2. Contacting us:
      </Text>

      <Text fontSize="md" mb={6}>
        1.2.1. You can e-mail us at{" "}
        <a href="mailto:office@m2hse.co.uk">office@m2hse.co.uk</a> or contact
        our Customer Service team by telephone on{" "}
        <a href="tel:+4402080599944">02080 599944</a> or by post 11 Humphries
        Close, RM9 5BX. If you are emailing us or writing to us please include
        details of your order to help us to identify it.
      </Text>

      <Text fontSize="md" mb={6}>
        1.2.2. If we have to contact you or give you notice in writing, we will
        do so by e-mail or by pre-paid post to the address you provide to us in
        your order.
      </Text>

      <Divider my={8} />

      <Heading as="h2" fontSize="xl" mb={4}>
        Use of Our Site
      </Heading>

      <Text fontSize="md" mb={6}>
        Your use of our site is governed by our{" "}
        <a href="/terms-of-website-use">Terms of Website Use</a>. Please take
        the time to read these, as they include important terms which apply to
        you.
      </Text>

      <Divider my={8} />

      <Heading as="h2" fontSize="xl" mb={4}>
        How We Use Your Personal Information
      </Heading>

      <Text fontSize="md" mb={6}>
        We only use your personal information in accordance with our{" "}
        <a href="/privacy-policy">Privacy Policy</a>. We use cookies on our
        site, details of which can be found in our{" "}
        <a href="/cookie-policy">Cookie Policy</a>. Please take the time to read
        our Privacy Policy and Cookie Policy, as they include important terms
        which apply to you.
      </Text>

      <Divider my={8} />

      <Heading as="h2" fontSize="xl" mb={4}>
        Age Restrictions
      </Heading>

      <Text fontSize="md" mb={6}>
        If you are a consumer, you may only purchase a Course from our site if
        you are at least 18 years old.
      </Text>

      <Divider my={8} />

      <Heading as="h2" fontSize="xl" mb={4}>
        Our Right to Vary These Terms
      </Heading>

      <Text fontSize="md" mb={6}>
        5.1. We may amend these Terms from time to time. Please look at the top
        of this page to see when these Terms were last updated and which Terms
        were changed. Every time you order a Course from us, the Terms in force
        at the time of your order will apply to the Contract between you and us.
        We may revise these Terms as they apply to your order from time to time
        to reflect changes in relevant laws and regulatory requirements.
      </Text>

      <Text fontSize="md" mb={6}>
        5.2. If we have to revise these Terms as they apply to your order, we
        will contact you to give you reasonable advance notice of the changes
        and let you know how to cancel the Contract if you are not happy with
        the changes.
      </Text>

      <Divider my={8} />

      <Heading as="h2" fontSize="xl" mb={4}>
        Purchasing a Course
      </Heading>

      <Heading as="h3" fontSize="xl" mb={2}>
        Order Process
      </Heading>

      <Text fontSize="md" mb={6}>
        6.1. Our shopping pages will guide you through the steps you need to
        take to place an order with us. Our order process allows you to check
        and amend any errors before submitting your order to us. Please take the
        time to read and check your order at each page of the order process.
      </Text>

      <Text fontSize="md" mb={6}>
        6.2. After you place an order, you will receive an e-mail from us
        acknowledging that we have received your order. However, please note
        that this does not mean that your order has been accepted. Our
        acceptance of your order will take place as described in clause 6.3.
      </Text>

      <Text fontSize="md" mb={6}>
        6.3. We will confirm our acceptance of your order to you by sending you
        an Acceptance Confirmation in the form of either:
      </Text>

      <Text fontSize="md" mb={6}>
        6.3.1. confirmation of receipt of payment and registration in the case
        of NVQ Courses.
      </Text>

      <Text fontSize="md" mb={6}>
        The Contract between us will only be formed when we send you the
        Acceptance Confirmation.
      </Text>

      <Text fontSize="md" mb={6}>
        6.4. If we are unable to supply you with a Course, for example because
        that Course is no longer available, is full or because of an error in
        the price on our site as referred to in clause 8.5, we will inform you
        of this by e-mail and we will not process your order. If you have
        already paid for the Course, we will refund you the full amount as soon
        as possible.
      </Text>

      <Divider my={8} />

      <Heading as="h2" fontSize="xl" mb={4}>
        6.5. NVQ Courses- Construction & Business
      </Heading>

      <Text fontSize="md" mb={6}>
        6.5.1. Payment terms: All NVQ Courses must be paid for as set out in the
        specific NVQ course specification and using the online payment facility
        provided by our site. Upon receipt of the correct payment, you will
        receive the login details to the online portal, the Course materials
        will be provided to you in digital format and you will be registered for
        the Course. Please note that once the online portal has been accessed,
        you cannot receive a refund for the deposit payment. In the event that
        any balancing payments are due (i.e., where a candidate has only paid a
        deposit), these must be paid on the earlier of completion of the Course
        or 6 months from the Course start date (Course start date is listed on
        the confirmation of registration). In the event that any payments are
        outstanding, no Course certificates will be issued. In the case of group
        bookings by business customers, this means that the whole group will not
        be certificated until all outstanding payments have been made.
      </Text>

      <Text fontSize="md" mb={6}>
        6.5.2. NVQ extension fee: all candidates are expected to complete the
        NVQ Course within one year. In the event that you take over one year to
        complete the NVQ Course, we reserve the right to charge a monthly NVQ
        Course extension fee of £100+VAT from the anniversary of your NVQ Course
        start date until completion of the Course and will send a monthly
        invoice for the same.
      </Text>

      <Text fontSize="md" mb={6}>
        6.5.3. Course rules: All NVQ Courses are subject to the NVQ Rules.
        Please ensure you have read and understood them. Failure to comply with
        these rules will mean you will not be eligible for the NVQ Certificate.
      </Text>

      <Text fontSize="md" mb={6}>
        6.5.4. Changing Course start dates: we will endeavour to accommodate
        requests to transfer you on to a different Course, but cannot guarantee
        that this will be possible. All transfers are subject to a rebooking fee
        of 20% of your Course fee which is payable immediately in order to
        secure the Course transfer and is non-refundable.
      </Text>

      <Text fontSize="md" mb={6}>
        6.5.5. Refunds and cancellations:
        <br />
        - If you are a consumer customer, given the digital nature of the
        Course, you do not have cooling off rights. Please see clause 7.3 below.
        Outside of this cooling off period, and provided you have not accessed
        the Course portal, you may cancel your Course and we will refund the
        Course fees less an administration fee of 20%. In all other cases, the
        Course fees will not be refunded.
        <br />
        - If you fail to attend the Course, no refund of the Course fee is due.
        If you wish to re-attend the Course, the full Course fees are payable.
        <br />- If you are a business customer, if you wish to cancel your
        purchase, you may do so provided you have not downloaded the course. We
        will refund the Course fees less an administration fee of 20% of the
        Course fee.
      </Text>

      <Divider my={8} />

      <Heading as="h2" fontSize="xl" mb={4}>
        Cancellation policy – CONSUMERS ONLY
      </Heading>

      <Text fontSize="md" mb={6}>
        7.1 If you are a consumer, you have a legal right to cancel your
        purchase during the period set out in Clause 7.2 below. This means that
        during the relevant period if you change your mind or decide for any
        other reason that you do not want to purchase a Course, you can notify
        us of your decision to cancel the Contract and receive a refund. Advice
        about your legal right to cancel the Contract is available from your
        local Citizens’ Advice Bureau or Trading Standards office.
      </Text>

      <Text fontSize="md" mb={6}>
        7.2. Your legal right to cancel a Contract starts from the date of the
        Acceptance Confirmation, which is when the Contract between us is
        formed. Your deadline for cancelling the Contract is 14 days after the
        date of the Acceptance Confirmation.
      </Text>

      <Text fontSize="md" mb={6}>
        7.3. To cancel a Contract, you just need to let us know that you have
        decided to cancel. The easiest way to do this is to contact us via email
        to office@m2hse.co.uk
      </Text>

      <Text fontSize="md" mb={6}>
        7.4. If you cancel your Contract in accordance with this clause 7, we
        will:
        <br />
        - refund you the price you paid for the Course; and
        <br />- make any refunds due to you as soon as possible and in any event
        within 14 Working days after you inform us of your decision to cancel
        the Contract.
      </Text>

      <Text fontSize="md" mb={6}>
        7.5. We will refund you on the credit card or debit card used by you to
        pay or by BACS transfer.
      </Text>

      <Text fontSize="md" mb={6}>
        7.6. Because you are a consumer, we are under a legal duty to supply
        products and services that are in conformity with these Terms. As a
        consumer, you have legal rights in relation to services that are faulty
        or not as described. These legal rights are not affected by your right
        of refund in this clause 7 or anything else in these Terms. Advice about
        your legal rights is available from your local Citizens’ Advice Bureau
        or Trading Standards office.
      </Text>

      <Divider my={8} />

      <Divider my={8} />

      <Heading as="h2" fontSize="xl" mb={4}>
        Prices and Payment
      </Heading>

      <Text fontSize="md" mb={6}>
        8.1. The prices for the Courses are calculated and payable in advance as
        set out at the on-line store page of our site. We shall be under no
        obligation to provide the Courses until the price or required deposit
        has been paid in full.
      </Text>

      <Text fontSize="md" mb={6}>
        8.2. The prices of the Courses will be as quoted on our site at the time
        you submit your order. We take all reasonable care to ensure that the
        prices of the Courses are correct at the time when the relevant
        information was entered onto the system. However please see clause 8.6
        for what happens if we discover an error in the price of the Courses you
        ordered.
      </Text>

      <Text fontSize="md" mb={6}>
        8.3. Prices for our Courses may change from time to time, but changes
        will not affect any order you have already placed.
      </Text>

      <Text fontSize="md" mb={6}>
        8.4. It is always possible that, despite our reasonable efforts, some of
        the Courses on our site may be incorrectly priced. If we discover an
        error in the price of the Courses you have ordered we will contact you
        to inform you of this error and we will give you the option of
        continuing to purchase the Courses at the correct price or cancelling
        your order. We will not process your order until we have your
        instructions. If we are unable to contact you using the contact details
        you provided during the order process, we will treat the order as
        cancelled and notify you in writing. Please note that if the pricing
        error is obvious and unmistakeable and could have reasonably been
        recognised by you as a mispricing, we do not have to provide the Courses
        to you at the incorrect (lower) price.
      </Text>

      <Text fontSize="md" mb={6}>
        8.5. All prices are expressed inclusive of any VAT payable unless
        otherwise stated. However, if the rate of VAT changes between the date
        of your order and the date of delivery, we will adjust the VAT you pay,
        unless you have already paid for the Courses in full before the change
        in VAT takes effect.
      </Text>

      <Text fontSize="md" mb={6}>
        8.6. You can only pay for the Courses using a debit card or credit card
        or our payment processor [WooCommerce/Stripe/PayPal]. We accept the
        following cards: AMEX, VISA, MASTERCARD, VISA DEBIT, MASTERCARD DEBIT.
      </Text>

      <Divider my={8} />

      <Heading as="h2" fontSize="xl" mb={4}>
        Intellectual property
      </Heading>

      <Text fontSize="md" mb={6}>
        The content of our site and all Courses sold via our site is protected
        by copyright, trade marks, database right and other intellectual
        property rights. You may retrieve and display the content of our site on
        a computer screen, store such content in electronic form on disk (but
        not any server or other storage device connected to a network) or print
        one copy of such content for your own personal, non-commercial use,
        provided you keep intact all and any copyright and proprietary notices.
        You may not otherwise reproduce, modify, copy or distribute or use for
        commercial purposes any of the materials or content on our site or for
        sale from our site without written permission from us.
      </Text>

      <Divider my={8} />

      <Heading as="h2" fontSize="xl" mb={4}>
        Our liability if you are a business
      </Heading>

      <Text fontSize="md" mb={6}>
        11.1. If we fail to comply with these Terms, we are responsible for loss
        or damage you suffer that is a foreseeable result of our breach of these
        Terms or our negligence, but we are not responsible for any loss or
        damage that is not foreseeable. Loss or damage is foreseeable if it is
        an obvious consequence of our breach or if it was contemplated by you
        and us at the time we entered into this contract.
      </Text>

      <Text fontSize="md" mb={6}>
        11.2. We only supply the Courses for domestic and private use. You agree
        not to use the Courses for any commercial, business or resale purposes,
        and we have no liability to you for any loss of profit, loss of
        business, business interruption, or loss of business opportunity.
      </Text>

      <Text fontSize="md" mb={6}>
        11.3. We do not in any way exclude or limit our liability for:
      </Text>

      <Text fontSize="md" mb={6}>
        a) death or personal injury caused by our negligence;
        <br />
        b) fraud or fraudulent misrepresentation;
        <br />
        c) any breach of the terms implied by section 12 of the Sale of Goods
        Act 1979 (title and quiet possession);
        <br />
        d) any breach of the terms implied by section 13 to 15 of the Sale of
        Goods Act 1979 (description, satisfactory quality, fitness for purpose
        and samples); and
        <br />
        e) defective products under the Consumer Protection Act 1987.
      </Text>

      <Text fontSize="md" mb={6}>
        11.4. It is your responsibility to ensure that the Courses are suitable
        for your requirements. We are happy to offer advice but accept no
        liability in the event that the Course content does not meet your
        requirements.
      </Text>

      <Text fontSize="md" mb={6}>
        11.5. We give no guarantee that by attending and/or completing the
        Courses that you will experience success in any business or activity
        that you may carry on following the Course.
      </Text>

      <Text fontSize="md" mb={6}>
        11.6. We make no promise that materials on our site are appropriate or
        available for use in locations outside the United Kingdom, and accessing
        our site from territories where its contents are illegal or unlawful is
        prohibited. If you choose to access our site from locations outside the
        United Kingdom, you do so on your own initiative and are responsible for
        compliance with local laws.
      </Text>

      <Divider my={8} />

      <Heading as="h2" fontSize="xl" mb={4}>
        Events outside our control
      </Heading>

      <Text fontSize="md" mb={6}>
        12.1. We will not be liable or responsible for any failure to perform,
        or delay in performance of, any of our obligations under a Contract that
        is caused by an Event Outside Our Control. An Event Outside Our Control
        is defined below in clause 13.2.
      </Text>

      <Text fontSize="md" mb={6}>
        12.2. An Event Outside Our Control means any act or event beyond our
        reasonable control, including without limitation pandemics, strikes,
        lock-outs or other industrial action by third parties, civil commotion,
        riot, invasion, terrorist attack or threat of terrorist attack, war
        (whether declared or not) or threat or preparation for war, fire,
        explosion, storm, flood, earthquake, subsidence, epidemic or other
        natural disaster, or failure of public or private telecommunications
        networks or impossibility of the use of railways, shipping, aircraft,
        motor transport or other means of public or private transport.
      </Text>

      <Text fontSize="md" mb={6}>
        12.3. If an Event Outside Our Control takes place that affects the
        performance of our obligations under a Contract:
      </Text>

      <Text fontSize="md" mb={6}>
        a) we will contact you as soon as reasonably possible to notify you
        <br />
        b) our obligations under a Contract will be suspended and the time for
        performance of our obligations will be extended for the duration of the
        Event Outside Our Control. Where the Event Outside Our Control affects
        our delivery of Courses to you, we will arrange a new delivery date with
        you after the Event Outside Our Control is over.
      </Text>

      <Text fontSize="md" mb={6}>
        12.4. You may cancel a Contract affected by an Event Outside Our
        Control. To cancel please contact us.
      </Text>

      <Divider my={8} />

      <Heading as="h2" fontSize="xl" mb={4}>
        Our assurance to you
      </Heading>

      <Text fontSize="md" mb={6}>
        13.1. We warrant that we have the right to supply the Courses and will
        use all reasonable skill and care in making the Courses available to you
        and in ensuring their availability.
      </Text>

      <Text fontSize="md" mb={6}>
        13.2. All Course contents have been carefully researched by us and are
        believed to be the most up-to-date and factually accurate information
        available at the time of the Course.
      </Text>

      <Text fontSize="md" mb={6}>
        13.3. We are continually seeking to improve the Courses. We reserve the
        right, at our discretion, to make changes to any part of the Courses
        provided that it does not materially reduce their content or
        functionality.
      </Text>

      <Divider my={8} />

      <Heading as="h2" fontSize="xl" mb={4}>
        Other important terms
      </Heading>

      <Text fontSize="md" mb={6}>
        14.1. We may transfer our rights and obligations under a Contract to
        another organisation, but this will not affect your rights or our
        obligations under these Terms. We will always notify you in writing or
        by posting on this webpage if this happens.
      </Text>

      <Text fontSize="md" mb={6}>
        14.2. You may only transfer your rights or your obligations under these
        Terms to another person if we agree in writing.
      </Text>

      <Text fontSize="md" mb={6}>
        14.3. This Contract is between you and us. No other person shall have
        any rights to enforce any of its terms.
      </Text>

      <Text fontSize="md" mb={6}>
        14.4. Each of the paragraphs of these Terms operates separately. If any
        court or relevant authority decides that any of them are unlawful or
        unenforceable, the remaining paragraphs will remain in full force and
        effect.
      </Text>

      <Text fontSize="md" mb={6}>
        14.5. If we fail to insist that you perform any of your obligations
        under these Terms, or if we do not enforce our rights against you, or if
        we delay in doing so, that will not mean that we have waived our rights
        against you and will not mean that you do not have to comply with those
        obligations. If we do waive a default by you, we will only do so in
        writing, and that will not mean that we will automatically waive any
        later default by you.
      </Text>

      <Text fontSize="md" mb={6}>
        14.6. If you are a consumer you may contact us as described in clause
        1.2.
      </Text>

      <Text fontSize="md" mb={6}>
        <Text as="span" fontWeight="bold">
          14.10. If you are a business,
        </Text>
      </Text>

      <List styleType="disc" ml={4} mb={6}>
        <ListItem>
          Any notice or other communication given by you to us, or by us to you,
          under or in connection with the Contract shall be in writing and shall
          be delivered personally, sent by pre-paid first-class post or other
          next working day delivery service or e-mail.
        </ListItem>
        <ListItem>
          A notice or other communication shall be deemed to have been received:
          if delivered personally, when left at our registered office; if sent
          by pre-paid first-class post or other next working day delivery
          service, at 9.00 am on the second Business Day after posting or if
          sent by e-mail, one Business Day after transmission.
        </ListItem>
        <ListItem>
          In proving the service of any notice, it will be sufficient to prove,
          in the case of a letter, that such letter was properly addressed,
          stamped and placed in the post and, in the case of an e-mail, that
          such e-mail was sent to the specified e-mail address of the addressee.
        </ListItem>
        <ListItem>
          The provisions of this clause shall not apply to the service of any
          proceedings or other documents in any legal action.
        </ListItem>
      </List>

      <Text fontSize="md" mb={6}>
        <Text as="span" fontWeight="bold">
          14.10. If you are a consumer,
        </Text>
        please note that these Terms are governed by English law. This means a
        Contract for the purchase of Courses through our site and any dispute or
        claim arising out of or in connection with it will be governed by
        English law. You and we both agree to that the courts of England and
        Wales will have non-exclusive jurisdiction. However, if you are a
        resident of Northern Ireland, you may also bring proceedings in Northern
        Ireland, and if you are a resident of Scotland, you may also bring
        proceedings in Scotland.
      </Text>

      <Text fontSize="md" mb={6}>
        <Text as="span" fontWeight="bold">
          14.10. If you are a business,
        </Text>
        a Contract and any dispute or claim arising out of or in connection with
        it or its subject matter or formation (including non-contractual
        disputes or claims) shall be governed by and construed in accordance
        with the law of England and Wales.
      </Text>

      <Text fontSize="md" mb={6}>
        <Text as="span" fontWeight="bold">
          14.10. If you are a business,
        </Text>
        , we both irrevocably agree that the courts of England and Wales shall
        have exclusive jurisdiction to settle any dispute or claim arising out
        of or in connection with a Contract or its subject matter or formation
        (including non-contractual disputes or claims).
      </Text>
    </Container>
  );
}
