import React from "react";
import {
  Box,
  useToast,
  Stack,
  Heading,
  Text,
  FormControl,
  Input,
  FormLabel,
  Button,
  Image,
  HStack,
  Flex,
  List,
  ListItem,
  useBreakpointValue,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
} from "@chakra-ui/react";
import { useForm } from "@formspree/react";
import axios from "axios";
import { API_BASE_URL } from "../../src/config/serverApiConfig";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import bgImage from "../assests/contactPage/95f5b1ddde33bf3e536b109dec5e68b5.png";
import formImage from "../assests/contactPage/CSCS-cards-hands-1024x683.jpg";
import proqualLogo from "../assests/contactPage/proqual.jpeg";
import m2hseLogo from "../assests/contactPage/Artboard 1.png";

axios.defaults.baseURL = API_BASE_URL;

const steps = [
  { title: "Personal", description: "Details" },
  { title: "Course", description: "Details" },
  { title: "Order", description: "Summary" },
];

const CampaignForm = () => {
  const navigate = useNavigate();

  const { activeStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  const useCreatePerson = async ({ firstname, lastname, email, phone }) => {
    const response = await axios.post("people/create", {
      firstname,
      lastname,
      email,
      phone,
    });
    return response.data;
  };

  const [state, handleSubmit] = useForm("xeqypwnz");
  const { isLoading, isError, error, data, mutate } =
    useMutation(useCreatePerson);

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    const firstname = e.target.elements.firstname.value;
    const lastname = e.target.elements.lastname.value;
    const email = e.target.elements.email.value;
    const phone = e.target.elements.phone.value;

    try {
      await mutate({ firstname, lastname, email, phone });
    } catch (error) {
      console.error(error);
    }
  };

  const toast = useToast();
  const handleCombinedSubmit = async (e) => {
    await handleSubmit(e);
    toast({
      title: "Success",
      description: "Your application was successfully submitted",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    await handleSubmitForm(e);
    e.target.reset();
    navigate("/level-one-page");
  };

  const isMobile = useBreakpointValue({ base: true, md: false });
  const formLayout = useBreakpointValue({ base: "column", md: "row" });
  const textSize = useBreakpointValue({ base: "20px", md: "18px" });
  const headingSize = useBreakpointValue({ base: "15px", md: "18px" });
  const inputSize = useBreakpointValue({ base: "45px", md: "40px" });
  const inputWidth = useBreakpointValue({ base: "90%", md: "300px" });
  const size = useBreakpointValue({ base: "sm", md: "md", lg: "lg" });

  return (
    <Box
      bgImage={bgImage}
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="cover"
      display={"flex"}
      alignItems={"center"}
      boxShadow="lg"
      height={{ base: "auto", md: "150vh" }}
      maxW="100%"
      mx={"auto"}
      position={"relative"}
      overflowY={"auto"}
    >
      <Box
        position={"absolute"}
        bgColor={"#FF3131"}
        opacity={"0.5"}
        maxW="100%"
        w={"full"}
        h={{ base: "full", md: "150vh" }}
      ></Box>
      <Flex
        opacity={"1"}
        zIndex={800}
        backgroundColor={"#F6F6F6"}
        width={"90%"}
        m={"20px"}
        mx={"auto"}
        direction={{ base: "column", md: "row" }}
        overflowY={"auto"}
      >
        <Box w={{ base: "100%", md: "25%" }} bgColor={"white"}>
          <Box width={"100%"} textAlign={"center"} p={5}>
            <Image
              src={m2hseLogo}
              height={{ base: "12vh", md: "16vh" }}
              objectFit={"contain"}
              mx={"auto"}
              alt="m2hse Logo"
            />
          </Box>
          {!isMobile && (
            <Box>
              <Image
                src={formImage}
                height={{ base: "40vh", md: "93vh" }}
                objectFit={"cover"}
                alt="Course Image"
              />
            </Box>
          )}
        </Box>

        <Box
          w={{ base: "100%", md: "73%" }}
          p={4}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
        >
          <Stack spacing={4}>
            <Box textAlign="center" width={"100%"} mx={"auto"}>
              <Heading fontSize={headingSize}>
                Your information is crucial. Please fill out the form below to
                help us set up your course
              </Heading>
              <Text
                mt={2}
                width={{ base: "auto", md: "70%" }}
                mx={"auto"}
                textAlign={"center"}
                fontWeight={"600"}
                color={"gray"}
                fontSize={headingSize}
              >
                We offer a diverse portfolio of construction education,
                featuring NVQ certifications, and CSCS card courses available
                across the UK.
              </Text>
            </Box>

            <Stepper
              size={size}
              cursor="pointer"
              colorScheme="red"
              index={activeStep}
            >
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepIndicator>
                    <StepStatus
                      incomplete={<StepNumber />}
                      complete={<StepNumber />}
                      active={<StepNumber />}
                    />
                  </StepIndicator>

                  <Box
                    flexShrink="0"
                    display={isMobile ? "block" : "flex"}
                    flexDirection={"column"}
                  >
                    <StepTitle>{step.title}</StepTitle>
                    {!isMobile && (
                      <StepDescription>{step.description}</StepDescription>
                    )}
                  </Box>

                  <StepSeparator />
                </Step>
              ))}
            </Stepper>

            <Box backgroundColor={"white"} width={"100%"} p={2}>
              <form
                onSubmit={handleCombinedSubmit}
                style={{ margin: "0px 10px 0px 20px" }}
                backgroundColor={"white"}
              >
                <Box>
                  <Flex
                    flexDirection={{ base: "column", md: "row" }}
                    width="100%"
                    justifyContent="space-between"
                    alignItems={{ base: "flex-start", md: "center" }}
                    textAlign={{ base: "start", md: "start" }}
                    mb={4}
                  >
                    <Text
                      width={{ base: "100%", md: "70%" }}
                      mb={{ base: 2.5, md: 0 }}
                      textColor="gray"
                      fontWeight="500"
                      fontSize="17px"
                    >
                      Let's start with the basic information to book your
                      Level-1 Award in Health and Safety in a Construction
                      Environment
                    </Text>
                    <Text
                      fontSize="sm"
                      fontWeight="bold"
                      width={{ base: "100%", md: "30%" }}
                      textAlign={{ base: "start", md: "end" }}
                    >
                      Step {activeStep + 1} of {steps.length}
                    </Text>
                  </Flex>
                </Box>
                <HStack spacing={2} flexDirection={formLayout}>
                  <FormControl id="firstname" mb={4}>
                    <FormLabel mb={2} fontSize={headingSize}>
                      First Name
                    </FormLabel>
                    <Input
                      height={inputSize}
                      fontSize={textSize}
                      borderRadius={"none"}
                      pl={"5px"}
                      mb={2}
                      w={inputWidth}
                      type="text"
                      name="firstname"
                      placeholder="Enter Your First Name"
                      required
                    />
                  </FormControl>
                  <FormControl id="lastname" mb={4}>
                    <FormLabel mb={2} fontSize={headingSize}>
                      Last Name
                    </FormLabel>
                    <Input
                      height={inputSize}
                      fontSize={textSize}
                      borderRadius={"none"}
                      mb={2}
                      width={inputWidth}
                      pl={"5px"}
                      type="text"
                      name="lastname"
                      placeholder="Enter Your Last Name"
                      required
                    />
                  </FormControl>
                </HStack>
                <HStack spacing={4} flexDirection={formLayout}>
                  <FormControl id="phone" mb={4}>
                    <FormLabel mb={2} fontSize={headingSize}>
                      Phone Number
                    </FormLabel>
                    <Input
                      height={inputSize}
                      fontSize={textSize}
                      borderRadius={"none"}
                      mb={2}
                      w={inputWidth}
                      pl={"5px"}
                      type="tel"
                      name="phone"
                      placeholder="Enter Your Number"
                      required
                    />
                  </FormControl>
                  <FormControl id="email" mb={4}>
                    <FormLabel mb={2} fontSize={headingSize}>
                      Email
                    </FormLabel>
                    <Input
                      height={inputSize}
                      fontSize={textSize}
                      borderRadius={"none"}
                      mb={2}
                      w={inputWidth}
                      pl={"5px"}
                      name="email"
                      type="email"
                      placeholder="Enter Your Email"
                      required
                    />
                  </FormControl>
                </HStack>
                <Box
                  display={"flex"}
                  flexDirection={"row-reverse"}
                  alignContent={"left"}
                  color={"white"}
                >
                  <Button
                    bgColor="#33B249"
                    color={"white"}
                    type="submit"
                    fontSize={headingSize}
                    disabled={state.submitting}
                    mt={4}
                    borderRadius={"lg"}
                    w="20%"
                    height={inputSize}
                  >
                    NEXT
                  </Button>
                </Box>
              </form>
            </Box>
          </Stack>
          <Box width={"100%"} bgColor={"white"} textAlign={"center"}>
            <Image
              src={proqualLogo}
              height={{ base: "12vh", md: "20vh" }}
              objectFit={"cover"}
              mx={"auto"}
              alt="Proqual Logo"
            />
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default CampaignForm;
