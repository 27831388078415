import {
  Heading,
  Text,
  Link,
  VStack,
  Button,
  UnorderedList,
  ListItem,
  Icon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  useBreakpointValue,
  Flex,
} from "@chakra-ui/react";
import { FaShoppingCart } from "react-icons/fa";
import { MdOutlineFileDownload as Download } from "react-icons/md";
import React, { useState } from "react";
import { MdChevronRight } from "react-icons/md";
import ContactForm from "./course-details/contactForm";

function NVQLevel6DiplomaPage() {
  const points = [
    "Remotely – ONLINE PORTFOLIO",
    "FAST TRACK COMPLETION 8-12 weeks",
    "Instalment payment plans available",
    "Leading to BLACK CSCS Card",
    "Access to MCIOB",
  ];
  const [name, setname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  return (
    <Flex
      direction={useBreakpointValue({ base: "column", md: "row" })}
      bg="white"
      p={useBreakpointValue({ base: 2, md: 4 })}
      justify="space-between"
      px={useBreakpointValue({ base: 6, md: "4rem" })}
      maxW="150ch"
      gap={useBreakpointValue({ base: 2, md: 5 })}
      mx="auto"
      position="relative"
      minHeight="100vh">
      <VStack
        spacing={4}
        align="stretch"
        w={useBreakpointValue({ base: "100%", md: "55%" })}
        px={useBreakpointValue({ base: 0, md: 10 })}
        my={useBreakpointValue({ base: 4, md: "3rem" })}>
        <Breadcrumb
          mb={5}
          fontSize="xs"
          fontWeight={500}
          spacing="4px"
          separator={<MdChevronRight color="gray.500" />}>
          <BreadcrumbItem>
            <BreadcrumbLink href="/">Home</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href="/nvq-level-6-contracting">
              NVQ Level 6 Contracting
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Heading as="h1" size="lg" color="#941C1C" mb={3}>
          Level 6 NVQ Diploma in Construction Contracting Operations Management
          – General
        </Heading>
        <UnorderedList
          style={{
            listStyle: "circle",
            color: "black",
            marginBottom: 15,
          }}>
          {points.map((point, index) => (
            <ListItem key={index}>
              <Text>{point}</Text>
            </ListItem>
          ))}
        </UnorderedList>
        <Text fontSize="xl" fontWeight="600">
          £1390.00 + VAT
        </Text>
        <Flex
          direction={useBreakpointValue({ base: "column", md: "row" })}
          justify={useBreakpointValue({ base: "center", md: "space-between" })}
          align="center"
          gap={useBreakpointValue({ base: 2, md: 5 })}>
          <Button
            w={useBreakpointValue({ base: "100%", md: "50%" })}
            borderRadius="2rem"
            color="white"
            bg="#941C1C"
            _hover={{ filter: "brightness(105%)" }}
            transition="all ease-in-out 200ms">
            <Icon as={FaShoppingCart} mr={3} />
            Book Now
          </Button>
          <Button
            w={useBreakpointValue({ base: "100%", md: "50%" })}
            borderRadius="2rem"
            color="#941C1C"
            border="1px solid #941C1C"
            bg="transparent"
            _hover={{ filter: "brightness(105%)" }}
            transition="all ease-in-out 200ms">
            <Icon as={Download} mr={3} />
            <Link
              href="https://www.proqualab.com/wp-content/uploads/2016/10/ProQual-L2-NVQ-Diploma-in-Decorative-Finishing-and-Industrial-Painting-Occs-July-2020.pdf"
              isExternal>
              Course Specifications
            </Link>
          </Button>
        </Flex>
        <VStack mt={6} textAlign="left" justify="start" align="start">
          <Text>
            The Level 6 NVQ Diploma in Construction Contracting Operations
            Management – General is designed to assess occupational competence
            in the workplace. Learners are required to demonstrate their skills,
            knowledge, and experience in construction-related project
            management. This qualification is suitable for managers who are
            responsible for a number of projects or resident project managers.
          </Text>

          <Text>
            This NVQ provides formal recognition of practicing as a construction
            project manager.
          </Text>

          <Text fontWeight="bold">Entry Requirements</Text>
          <Text>
            You must be able to demonstrate that you can meet the skills,
            knowledge and experience requirements of the NVQ before enrollment.
          </Text>

          <Text fontWeight="bold">How long will my NVQ take to complete?</Text>
          <Text>
            An assessment plan will be agreed during your Initial
            assessment/Induction and a planned end date agreed. However, we
            would expect your qualification to be complete with 12 weeks
            depending on the learner’s time availability.
          </Text>

          <Text fontWeight="bold">Assessment contents & units</Text>

          <Text>
            Mandatory Units
            <br />
            1. Maintain Health, Safety and Welfare Systems in Construction
            Contracting Operations Management
            <br />
            2. Plan, Allocate and Monitor Work in Own Area of Responsibility
            <br />
            3. Chair Meetings and Take Decisions in Construction Contracting
            Operations Management
            <br />
            4. Develop and Maintain Working Relationships and Personal
            Development in Construction Contracting Operations Management
            <br />
            <Text
              style={{ color: "#121212", marginTop: 7, fontWeight: "bold" }}>
              Optional Units
            </Text>
            1. Plan Measured Surveys in Construction Contracting Operations
            Management
            <br />
            2. Establish the Condition of Property in Construction Contracting
            Operations Management
            <br />
            3. Control Contract Work in Construction Contracting Operations
            Management
          </Text>

          <Text fontWeight="bold">Industry recognition & benefits</Text>
          <Text>
            The NVQ L6 Diploma in Construction Site Management qualifies the
            holder to apply for the Construction Skills Certification Scheme
            (CSCS) black card
            <Link
              href="https://www.cscs.uk.com/card-type/manager/"
              color="blue.500"
              isExternal>
              CSCS Card
            </Link>
          </Text>
          <Text>
            A level 6 in Construction Site Management allows you access to
            becoming Chartered with MCIOB. Holders of a Level 6 NVQ will provide
            academic eligibility to undertake the full Industry Professional
            Review.
            <Link
              href="https://www.ciob.org/routes/i-have-nvq"
              color="blue.500"
              isExternal>
              CSCS Card
            </Link>
          </Text>
        </VStack>
      </VStack>
      <ContactForm />
    </Flex>
  );
}

export default NVQLevel6DiplomaPage;
