import { Result, Button } from "antd";
import { useNavigate } from "react-router-dom";

export default function NotFound({ entity = "/" }) {
  const naviage = useNavigate();
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you are looking for does not exist."
      extra={
        <Button type="primary" onClick={() => naviage(entity)}>
          Go Home
        </Button>
      }
    />
  );
}
