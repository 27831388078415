import {
  Heading,
  Text,
  VStack,
  Button,
  Icon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
} from "@chakra-ui/react";
import { FaShoppingCart } from "react-icons/fa";
import React from "react";
import { useQuery } from "react-query";
import { MdChevronRight } from "react-icons/md";
import ContactForm from "../nvqPages/course-details/contactForm";
import { useCart } from "../cart/addToCart";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

function SsstsOnlineRefresherOneDay() {
  const { isPending, error, data } = useQuery(["product"], () => {
    return axios.get(`product/filter`, {
      params: {
        filter: "detailsPage",
        equal: "/sssts-online-refresher",
      },
    });
  });
  const { addToCart } = useCart();
  const navigate = useNavigate();
  const AddToCart = (data) => {
    const { _id, name, price } = data;
    addToCart(
      {
        _id,
        name,
        price,
        vat: data.productCategory.vat,
        quantity: 1,
        type: data.productCategory.type,
      },
      0
    );
    navigate("/checkout");
  };
  return (
    <Flex
      direction={{ base: "column", md: "row" }}
      bg="white"
      p={2}
      justify={"space-between"}
      px={{ base: 4, md: "4rem" }}
      maxW={"150ch"}
      gap={2}
      mx="auto"
      position="relative"
      minHeight="100vh"
    >
      <VStack
        spacing={4}
        align="stretch"
        w={{ base: "100%", md: "55%" }}
        px={{ base: 0, md: 10 }}
        my={{ base: 4, md: "3rem" }}
      >
        <Breadcrumb
          mb={5}
          fontSize={"xs"}
          fontWeight={500}
          spacing="4px"
          separator={<MdChevronRight color="gray.500" />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink href="/">Home</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href="/citb-courses">CITB Courses</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href="/sssts-online-refresher">
              SSSTS
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Heading as="h1" size="lg" color="#941C1C" mb={3}>
          SSSTS (Site Supervisors Safety Training Scheme) training course is one
          of the courses that form part of the CITB{" "}
          <Link to="https://www.bookmycourse.co.uk/courses/smsts_and_site_safety_plus_284/">
            Site Safety Plus
          </Link>{" "}
          range
        </Heading>

        <Text fontSize={"xl"} fontWeight={"600"}>
          £{data?.data.result[0].price} + VAT
        </Text>
        <Flex
          direction={{ base: "column", md: "row" }}
          justify={"space-between"}
          align={"center"}
          gap={2}
        >
          <Button
            w={{ base: "100%", md: "50%" }}
            borderRadius={"2rem"}
            color={"white"}
            bg={"#941C1C"}
            _hover={{ filter: "brightness(105%)" }}
            onClick={() => {
              AddToCart(data?.data.result[0]);
            }}
          >
            <Icon as={FaShoppingCart} mr={3} />
            Book Now
          </Button>
        </Flex>
        <VStack mt={6} textAlign={"left"} justify={"start"} align={"start"}>
          <Text>
            The SSSTS Refresher course is one of the newer additions to the Site
            Safety Plus range of courses. It is a 1-day course and is aimed at
            delegates who have previously passed the 2-day SSSTS course
            (Supervisor’s Safety Training Scheme). Candidates must be able to
            provide proof of attendance to the full SSSTS course to be eligible
            for the two-day SSSTS Refresher course.
          </Text>
          <Text>
            Successful delegates will receive the CITB Site Safety Plus
            certificate which will be valid for 5 years.
          </Text>
          <Text fontWeight={"bold"}>
            Please note that candidate’s current SSSTS or SSSTS Refresher
            certificates must not have expired; if this is the case, the
            candidate will need to re-take the full two-day SSSTS course.
          </Text>

          <Text fontWeight="bold">CITB Course Detail </Text>
          <Text>
            This CITB SSSTS Refresher course aims to bring the candidate’s
            knowledge up-to-date with the latest changes in legislation and how
            these affect site operations.
            <Text>This CITB course includes the following elements: </Text>
            <br />
            - Recent changes to legislation surrounding construction site
            health, safety and welfare
            <br />
            - How health and safety law is structured
            <br />- Why method statements are a legal requirement and how they
            work
            <br />- How their role fits in with controlling site safety, in a
            safe manner
            <br />- Behavioural safety
            <br />- Occupational health supervision
            <br />- Communication on a site and the routes available within site
            activity for effective communication
          </Text>
          <Text>
            Full attendance is mandatory, and candidates will be continually
            assessed during the course. There is a multiple-choice assessment
            test at the end of the course.
          </Text>
          <Text>
            Successful candidates will receive a CITB Site Safety Plus
            certificate, which is issued on completion and valid for 5 years.{" "}
          </Text>
        </VStack>
      </VStack>
      <ContactForm />
    </Flex>
  );
}

export default SsstsOnlineRefresherOneDay;
