const testimonials = [
  {
    name: "Michael Lee-Brooks",
    title: "Director",
    testimonial:
      "When I first heard of M2HSE I thought they are same as everyone else, some company that no one know or trusts, but working with them I realised that their professionalism and excellence are differentiating them from other companies. Highly recommend.",
  },
  {
    name: "John Mitchell",
    title: "Site Manager",
    testimonial:
      "The company is very serious and reliable. I contacted them in regard with my NVQ Level 6 for Construction Site managemet and was helped and guided very professionally.",
  },
  {
    name: "Karen Walt",
    title: "Office Manger",
    testimonial:
      "M2HSE are very professional and I cannot recommend them enough for their good work and exemplary customer service.",
  },
];

export default testimonials;
