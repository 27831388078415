import React from "react";
import { Box, Text, VStack, Heading } from "@chakra-ui/react";

const ThankYouPage = () => {
  return (
    <Box textAlign="center" py={10} px={6}>
      <VStack spacing={3}>
        <Heading as="h2" size="xl" mt={6} mb={4}>
          Thank You!
        </Heading>
        <Text color="gray.600">
          Thank you for your inquiry. We will get back to you soon.
        </Text>
      </VStack>
    </Box>
  );
};

export default ThankYouPage;
