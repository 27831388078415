import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  VStack,
  Select,
  useToast,
  Heading,
  Text,
  Flex,
  IconButton,
  Image,
  Spacer,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import axios from "axios";
import { MdDelete } from "react-icons/md";
import { FaPlus, FaMinus } from "react-icons/fa6";
import currency from "currency.js";
import { useCart } from "./addToCart";

const CheckOutPage = () => {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    companyName: "",
    country: "",
    address: "",
    apartment: "",
    city: "",
    postalCode: "",
    phone: "",
    email: "",
  });

  const [cart, setCart] = useState([]);
  const { removeFromCart, quantityChange } = useCart();

  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem("cart")) || [];
    if (storedCart && Array.isArray(storedCart)) {
      const processedItems = storedCart.map((item) => {
        const price = currency(item.price, { symbol: "£" });
        const total = price
          .multiply(item.quantity)
          .add(price.multiply(item.vat / 100));
        return { ...item, total }; // Add total to each item
      });
      setCart(processedItems);
    }
  }, []);

  const handleDelete = (index) => {
    removeFromCart(index);
    const newCart = [...cart];
    newCart.splice(index, 1);
    setCart(newCart);
    localStorage.setItem("cart", JSON.stringify(newCart));
  };

  const handleQuantityChange = (index, value) => {
    quantityChange(index, value);
    const newCart = [...cart];
    newCart[index].quantity = Math.max(1, value);
    setCart(newCart);
    localStorage.setItem("cart", JSON.stringify(newCart));
  };

  const calculateTotalCount = () => {
    return cart.reduce((total, item) => total + item.quantity, 0);
  };

  const calculateTotalPrice = () => {
    return cart.reduce((totalPrice, item) => {
      const total = currency(currency(item.price).multiply(item.vat / 100), {
        symbol: "£",
      })
        .add(item.price)
        .multiply(item.quantity);
      return (totalPrice = currency(totalPrice, { symbol: "£" }).add(total));
    }, 0);
  };

  const [cartItems, setCartItems] = useState([]);
  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem("cart")) || [];
    setCartItems(storedCart);
  }, []);

  const toast = useToast();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const createPeople = async (formData) => {
    try {
      const response = await axios.post("/people/create", formData);
      const people = response?.data.result;
      if (response?.data.success) {
        const storedCart = localStorage.getItem("cart");
        if (storedCart) {
          let carts = JSON.parse(storedCart);
          let interestedIn = [];
          carts.map((product) => {
            interestedIn.push(product._id);
          });
          formData["interestedIn"] = interestedIn;
        }
        formData["type"] = "people";
        formData["source"] = "M2HSE website";
        formData["people"] = response.data.result._id;
        let data = await axios.post("/client/create", formData);
        if (data?.data.success) {
          localStorage.setItem("client", JSON.stringify(data.data.result));

          const newCartIds = [];
          const newTotalProduct = [];
          for (const item of cart) {
            const cart = {
              client: data?.data.result._id,
              people: people._id,
              quantity: item.quantity,
              type: item.type,
              product: item._id,
              vat: item.vat || 0,
              itemName: item.name,
              price: item.price,
              total: item.price * item.quantity,
            };

            try {
              const response = await axios.post("/orderDetails/create", cart);

              if (response.data && response.data.result._id) {
                newCartIds.push(response.data.result._id); // Add new cart ID to local array
                newTotalProduct.push(response.data.result); // Add new product to local array
              }
            } catch (error) {
              console.error("Error adding item to cart:", error);
            }
          }
          toast({
            title: "Success",
            description: "Form submitted successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          localStorage.setItem("ids", JSON.stringify(newCartIds));
          navigate("/payment");
        }
        if (data?.data.success === false) {
          toast({
            title: "Error",
            description: response.data.message,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      }

      return response.data;
    } catch (error) {
      toast({
        title: "Faild",
        description: error.response.data.data.error.details[0].message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const { isLoading, isError, error, data, mutate } = useMutation(createPeople);
  // payment method

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check if any required field is empty
    for (const key in formData) {
      if (
        formData[key] === "" &&
        key !== "apartment" &&
        key !== "companyName"
      ) {
        toast({
          title: "Error",
          description: `${key} is required`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return; // Stop form submission
      }
    }
    // If all required fields are filled, proceed with submission
    formData["message"] =
      "People created from the website through the time of payment";
    try {
      await mutate(formData);
    } catch (error) {
      toast({
        title: "Failed",
        description: "Your form submission failed. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const countries = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Côte d'Ivoire",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo (Congo-Brazzaville)",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czechia (Czech Republic)",
    "Democratic Republic of the Congo",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Holy See",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar (formerly Burma)",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Korea",
    "North Macedonia",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine State",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Korea",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States of America",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];

  const countryOptions = countries.map((country) => (
    <option key={country}>{country}</option>
  ));

  const EmptyCartMessage = () => (
    <VStack spacing={4} alignItems="center">
      <Image
        src={require("../../assests/cartPage/cart1.webp")}
        alt="Empty Cart"
        maxW="200px"
      />
      <Text fontSize="lg" fontWeight="bold" color="gray.500" textAlign="center">
        Your cart is empty!
      </Text>
      <Text fontSize="md" color="gray.500" textAlign="center">
        Add some items to your cart to continue shopping.
      </Text>
    </VStack>
  );

  return (
    <Box display={"flex"} mx="auto">
      <Box
        flex={"1"}
        boxShadow="md"
        borderRadius="md"
        padding="20px"
        backgroundColor="white"
        margin="10px"
      >
        <Heading
          fontSize={{ base: "xl", md: "2xl" }}
          mb={4}
          color="#941C1C"
          alignItems={"flex-start"}
        >
          Items added to Cart
        </Heading>
        <VStack align="stretch">
          {cart.length === 0 ? (
            <EmptyCartMessage />
          ) : (
            cart.map((item, index) => (
              <Box
                key={index}
                paddingBottom={4}
                borderBottomWidth="2px"
                borderRadius="md"
              >
                <Flex justifyContent="space-between" alignItems="center">
                  <Text fontSize="md" fontWeight={"bold"}>
                    {item.name}
                  </Text>
                  <IconButton
                    icon={<MdDelete />}
                    onClick={() => handleDelete(index)}
                    aria-label="Delete Product"
                    variant="ghost"
                    colorScheme="red"
                    size="sm"
                  />
                </Flex>

                <Text fontSize="sm" mt={2}>
                  Price: {currency(item.price, { symbol: "£" }).format()} +
                  (VAT: {item.vat}%)
                </Text>
                <Text fontSize="sm" fontWeight={"bold"}>
                  Total:{" "}
                  {currency(currency(item.price).multiply(item.vat / 100), {
                    symbol: "£",
                  })
                    .add(item.price)
                    .multiply(item.quantity)
                    .format()}
                </Text>
              </Box>
            ))
          )}
          {cart.length > 0 && (
            <Box>
              <Text fontSize="md" mt={4}>
                Total Count: {calculateTotalCount()}
              </Text>
              <Text fontSize="md" fontWeight={"bold"} mt={2}>
                Total Price: {calculateTotalPrice().format()}
              </Text>
              <Spacer />
              <Link to="/">
                <Button
                  mt={4}
                  bgColor="#941C1C"
                  color="white"
                  borderRadius="none"
                  _hover={{ bgColor: "#6B7280" }}
                >
                  Continue shopping...
                </Button>
              </Link>
            </Box>
          )}
        </VStack>
      </Box>
      <Box flex={"1"}>
        <Box
          boxShadow="md"
          borderRadius="md"
          padding="20px"
          backgroundColor="white"
          margin="10px"
        >
          <VStack
            spacing={{ base: "3", md: "5" }}
            fontWeight={"bold"}
            alignItems="flex-start"
          >
            <Heading color="#941C1C" fontSize={{ base: "xl", md: "2xl" }}>
              Check Out
            </Heading>

            <FormControl isRequired>
              <FormLabel>First Name</FormLabel>
              <Input
                name="firstname"
                border="1px solid #6B7280"
                placeholder="First name"
                borderRadius="none"
                value={formData.firstname}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Last Name</FormLabel>
              <Input
                name="lastname"
                border="1px solid #6B7280"
                placeholder="Last name"
                borderRadius="none"
                value={formData.lastname}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Company Name</FormLabel>
              <Input
                name="companyName"
                border="1px solid #6B7280"
                placeholder="Company name"
                borderRadius="none"
                value={formData.companyName}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Country/Region</FormLabel>
              <Select
                name="country"
                value={formData.country}
                onChange={handleChange}
                placeholder="Select country"
                border="1px solid #6B7280"
                borderRadius="none"
              >
                {countryOptions}
              </Select>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Street Address</FormLabel>
              <Input
                name="address"
                border="1px solid #6B7280"
                placeholder="Street address"
                borderRadius="none"
                value={formData.address}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Apartment, suite, unit, etc.</FormLabel>
              <Input
                name="apartment"
                border="1px solid #6B7280"
                placeholder="Apartment, suite, etc."
                borderRadius="none"
                value={formData.apartment}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Town/City</FormLabel>
              <Input
                name="city"
                border="1px solid #6B7280"
                placeholder="Town/City"
                borderRadius="none"
                value={formData.city}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>postalCode</FormLabel>
              <Input
                name="postalCode"
                border="1px solid #6B7280"
                placeholder="postalCode"
                borderRadius="none"
                value={formData.postalCode}
                onChange={handleChange}
                pattern="[A-Za-z0-9]*"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Phone</FormLabel>
              <Input
                maxLength={10}
                name="phone"
                type="number"
                border="1px solid #6B7280"
                placeholder="Phone"
                borderRadius="none"
                value={formData.phone}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Email Address</FormLabel>
              <Input
                type="email"
                name="email"
                border="1px solid #6B7280"
                placeholder="Email Address"
                borderRadius="none"
                value={formData.email}
                onChange={handleChange}
              />
            </FormControl>
            <Button
              bgColor="#941C1C"
              color="white"
              borderRadius="none"
              _hover={{ bgColor: "#6B7280" }}
              width="50%"
              onClick={handleSubmit}
            >
              Proceed to payment
            </Button>
          </VStack>
        </Box>
      </Box>
    </Box>
  );
};

export default CheckOutPage;
