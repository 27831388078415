import React from "react";
import {
  Box,
  Heading,
  Input,
  Button,
  FormControl,
  FormLabel,
  Textarea,
  VStack,
  Text,
  Flex,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  useToast,
} from "@chakra-ui/react";
import { useForm, ValidationError } from "@formspree/react";
import axios from "axios";
import { AiFillPhone, AiFillMail, AiFillEnvironment } from "react-icons/ai";
import { MdChevronRight } from "react-icons/md";
import { API_BASE_URL } from "../../src/config/serverApiConfig";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
axios.defaults.baseURL = API_BASE_URL;

const ContactForm = () => {
  const navigate = useNavigate();

  const useCreatePerson = async ({
    firstname,
    lastname,
    email,
    phone,
    message,
  }) => {
    const response = await axios.post("people/create", {
      firstname,
      lastname,
      email,
      phone,
      message,
    });
    return response.data;
  };

  const [state, handleSubmit] = useForm("xeqypwnz");
  const { isLoading, isError, error, data, mutate } =
    useMutation(useCreatePerson);

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    const firstname = e.target.elements.firstname.value;
    const lastname = e.target.elements.lastname.value;
    const email = e.target.elements.email.value;
    const phone = e.target.elements.phone.value;
    const message = e.target.elements.message.value;

    try {
      await mutate({ firstname, lastname, email, phone, message });
    } catch (error) {
      console.error(error);
    }
  };
  const toast = useToast();
  const handleCombinedSubmit = async (e) => {
    await handleSubmit(e); // Trigger useForm's handleSubmit
    toast({
      title: "Success",
      description: "Form submitted successfully",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    await handleSubmitForm(e);
    e.target.reset();
    navigate("/thank-you");
  };

  return (
    <>
      <Box bgGradient="linear(to-r, white, #f8f8f8)" p={4}>
        <Breadcrumb
          mb={5}
          fontSize={"sm"}
          fontWeight={500}
          spacing="4px"
          separator={<MdChevronRight color="gray.500" />}
          alignSelf="flex-start">
          <BreadcrumbItem>
            <BreadcrumbLink href="/">Home</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href="/contact">Contact</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex
          justify="center"
          align="center"
          minH="100vh"
          px={{ base: "20px", md: 0 }}
          flexDir={{ base: "column", md: "row" }}
          alignItems="stretch">
          {/* <Box
            borderRadius="md"
            p="20px"
            w={{ base: "100%", md: "50%" }}
            bg="white"
            my={{ base: "10px", md: 0 }}
            style={{
              backgroundImage: `url(${require("../assests/contactPage/contact.avif")})`,
              backgroundSize: "cover",

              backgroundPosition: "center",
            }}>
            <VStack
              spacing={{ base: 3, md: 5 }}
              fontWeight="bold"
              alignItems="flex-start">
              <Heading fontSize={{ base: "xl", md: "2xl" }}>
                Contact Details
              </Heading>
              <Flex borderRadius={"lg"} p={4} flexDir="column">
                <Flex alignItems="center" mb={2}>
                  <AiFillPhone color="#941C1C" size="20px" />
                  <Text ml="2">02080 599944</Text>
                </Flex>
                <Flex alignItems="center" mb={2}>
                  <AiFillMail color="#941C1C" size="20px" />
                  <Text ml="2">office@m2hse.co.uk</Text>
                </Flex>
                <Flex alignItems="center">
                  <AiFillEnvironment color="#941C1C" size="20px" />
                  <Text ml="2">
                    Ceme Business Campus, Commercial 1, Marsh Way, Rainham, RM13
                    8EU
                  </Text>
                </Flex>
              </Flex>
            </VStack>
          </Box> */}
          <Box
            boxShadow="md"
            borderRadius="md"
            p="20px"
            w={{ base: "100%", md: "50%" }}
            bg="white"
            my={{ base: "10px", md: 0 }}>
            <VStack
              spacing={{ base: 3, md: 5 }}
              fontWeight="bold"
              alignItems="flex-start">
              <Heading color="#941C1C" fontSize={{ base: "xl", md: "2xl" }}>
                Can’t find what you are looking for?
              </Heading>
              <Heading fontSize={{ base: "xl", md: "2xl" }}>
                Contact Details
              </Heading>
              <Flex borderRadius={"lg"} p={4} flexDir="column">
                <Flex alignItems="center" mb={2}>
                  <AiFillPhone color="#941C1C" size="20px" />
                  <Text ml="2">02080 599944</Text>
                </Flex>
                <Flex alignItems="center" mb={2}>
                  <AiFillMail color="#941C1C" size="20px" />
                  <Text ml="2">office@m2hse.co.uk</Text>
                </Flex>
                <Flex alignItems="center">
                  <AiFillEnvironment color="#941C1C" size="20px" />
                  <Text ml="2">
                    Ceme Business Campus, Commercial 1, Marsh Way, Rainham, RM13
                    8EU
                  </Text>
                </Flex>
              </Flex>
              <form
                style={{ width: "90%", margin: "auto" }}
                onSubmit={handleCombinedSubmit}>
                <FormControl>
                  <FormLabel mb={0}>First Name</FormLabel>
                  <Input
                    id="firstname"
                    type="text"
                    name="firstname"
                    border="1px solid #6B7280"
                    placeholder="First name"
                    borderRadius="none"
                    mb={4}
                    required
                  />
                </FormControl>
                <FormControl>
                  <FormLabel mb={0}>Last Name</FormLabel>
                  <Input
                    id="lastname"
                    type="text"
                    name="lastname"
                    border="1px solid #6B7280"
                    placeholder="Last name"
                    borderRadius="none"
                    mb={4}
                    required
                  />
                </FormControl>

                <FormControl>
                  <FormLabel mb={0}>Email ID</FormLabel>
                  <Input
                    id="email"
                    type="email"
                    name="email"
                    border="1px solid #6B7280"
                    placeholder="Email Id"
                    borderRadius="none"
                    mb={4}
                    required
                  />
                  <ValidationError
                    prefix="Email"
                    field="email"
                    errors={state.errors}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel mb={0}>Phone Number</FormLabel>
                  <Input
                    id="phone"
                    name="phone"
                    type="text"
                    border="1px solid #6B7280"
                    placeholder="Phone Number"
                    borderRadius="none"
                    mb={4}
                    required
                  />
                </FormControl>
                <FormControl>
                  <FormLabel mb={0}>Message</FormLabel>
                  <Textarea
                    id="message"
                    type="text"
                    name="message"
                    border="1px solid #6B7280"
                    placeholder="Message"
                    borderRadius="none"
                    mb={4}
                  />
                </FormControl>
                <Button
                  mx="auto"
                  disabled={state.submitting}
                  type="submit"
                  bg="#941C1C"
                  color="white"
                  borderRadius="none"
                  w={{ base: "100%", md: "50%" }}
                  _hover={{ bg: "#6B7280" }}>
                  Submit
                </Button>
              </form>
            </VStack>
          </Box>
        </Flex>
      </Box>
    </>
  );
};

export default ContactForm;
