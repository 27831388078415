import { Container, Text } from "@chakra-ui/react";

export default function CookiesPolicyPage() {
  return (
    <Container maxW="full" pt={4}>
      <Text fontSize="xl" fontWeight="bold" mb={4}>
        M2HSE Training LTD Cookies Policy
      </Text>
      <Text fontSize="md" fontWeight="bold" mb={4}>
        Cookie Policy
      </Text>
      <Text fontSize="md" mb={4}>
        This service policy (''Policy'') describes what cookie are and how
        they’re being used by the M2HSE.co.uk website (''Website'') or
        ''Service'') and any of its related products and services (collectively,
        ''Services''). This policy is a legally binding agreement between you
        (''User'', ''you'' or ''your'') and M2HSE Training LTD (''M2HSE Training
        LTD'', ''we'', ''us'' or ''our''). If you are entering into this Policy
        on behalf of a business or other legal entity, you represent that you
        have the authority to bind such entity to this Policy, in which case the
        terms ''User'', ''you'' or ''your'' shall refer to such entity. If you
        do not have such authority, or if you do not agree with the terms of
        this Policy, you must not accept this Policy and may not access and use
        the Website and Services. You should read this Policy so you can
        understand the types of cookies we use, the information we collect using
        cookies and how that information is used. It also describes the choices
        available to you regarding accepting or declining the use of cookies.
        For further information on how we use, store and keep your personal data
        secure, see our privacy policy.
      </Text>
      <Text fontSize="md" fontWeight="bold" mb={4}>
        What are cookies?
      </Text>
      <Text fontSize="md" mb={4}>
        Cookies are small pieces of data stored in text files that are saved on
        your computer or other devices when websites are loaded in a browser.
        They are widely used to remember you and your preferences, either for a
        single visit (through a ‘’session cookie’’) or for multiple repeat
        visits (using a ‘’persistent cookie’’).
      </Text>
      <Text fontSize="md" mb={4}>
        Session cookie are temporary cookies that are used during the course of
        your visit to the Website, and they expire when you close the web
        browser.
      </Text>
      <Text fontSize="md" mb={4}>
        Persistent cookies are used to remember your preferences within our
        Website and remain on your desktop or mobile device even after you close
        your browser or restart your computer. They ensure a consistent and
        efficient experience for you while visiting the Website and Services.
      </Text>
      <Text fontSize="md" mb={4}>
        Cookies may be set by the Website (''first-party cookies''), or by third
        parties, such as those who serve content or provide advertising or
        analytics services on the Website (''first-party cookies''). These third
        parties can recognize you when you visit our website and also when you
        visit certain other websites.
      </Text>
      <Text fontSize="md" fontWeight="bold" mb={4}>
        What type of cookies do we use?
      </Text>
      <Text fontSize="md" fontWeight="bold" mb={4}>
        Necessary cookies
      </Text>
      <Text fontSize="md" mb={4}>
        Necessary cookies allow us to offer you the best possible experience
        when accessing and navigating through our Website and using its
        features. For example, these cookies let us recognize that you have
        created an account and have logged into that account to access the
        content.
      </Text>
      <Text fontSize="md" fontWeight="bold" mb={4}>
        Functionality cookies
      </Text>
      <Text fontSize="md" mb={4}>
        Functionality cookies let us operate the Website and Services in
        accordance with the choices you make. For example, we will recognize
        your username and remember how you customized the Website and Services
        during future visits.
      </Text>
      <Text fontSize="md" fontWeight="bold" mb={4}>
        What are your cookie options?
      </Text>
      <Text fontSize="md" mb={4}>
        If you don’t like the idea of cookies or certain types of cookies, you
        can change your browser’s settings to delete cookies that have already
        been set and to not accept new cookies. Visit internetcookies.com to
        learn more about how to do this.
      </Text>
      <Text fontSize="md" fontWeight="bold" mb={4}>
        Changes and amendments
      </Text>
      <Text fontSize="md" mb={4}>
        We reserve the right to modify this Policy or its terms related to the
        Website and Services at any time at our discretion. When we do, we will
        reserve the updated date at the bottom of this page. We may also provide
        notice to you in other ways at our discretion, such as through the
        contact information you have provided.
      </Text>
      <Text fontSize="md" mb={4}>
        An updated version of this Policy will be effective immediately upon the
        posting of the revised Policy unless otherwise specified. Your continued
        use of the Website and Services after the effective date of the revised
        Policy (or such other act specified at that time) will constitute your
        consent to those changes.
      </Text>
      <Text fontSize="md" fontWeight="bold" mb={4}>
        Acceptance of this policy
      </Text>
      <Text fontSize="md" mb={4}>
        You acknowledge that you have read this Policy and agree to all its
        terms and conditions. By accessing and using the Website and Services
        you agree to be bound by this Policy. If you do not agree to abide by
        the terms of this Policy, you are not authorized to access or use the
        Website and Services.
      </Text>
      <Text fontSize="md" fontWeight="bold" mb={4}>
        Contacting us
      </Text>
      <Text fontSize="md" mb={4}>
        If you have any questions, concerns, or complaints regarding this Policy
        or the use of cookies, we encourage you to contact us using the details
        below:
      </Text>
      <a
        href="mailto:office@m2hse.co.uk"
        fontWeight="bold"
        color="blue"
        fontSize="md"
        mb={4}>
        office@m2hse.co.uk
      </a>
      <Text fontSize="md" fontWeight="bold" mb={4}>
        This document was last updated on 19th October 2023
      </Text>
    </Container>
  );
}
