import {
  Heading,
  Text,
  VStack,
  Button,
  UnorderedList,
  ListItem,
  Icon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  useBreakpointValue,
  Flex,
} from "@chakra-ui/react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Link as ChakraLink } from "@chakra-ui/react";
import { FaShoppingCart } from "react-icons/fa";
import { MdOutlineFileDownload as Download } from "react-icons/md";
import React from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { MdChevronRight } from "react-icons/md";
import AOS from "aos";
import ContactForm from "../contactForm";
import { useCart } from "../../../cart/addToCart";

function NvqHealthAndSafetyDetailPage2() {
  React.useEffect(() => {
    AOS.init({
      duration: 1200,
      easing: "ease-in-out-back",
    });
  }, []);

  const points = [
    "All remotely online portfolio",
    "FAST TRACK COMPLETION 8-12 weeks",
    "Leading to Gold CSCS card",
    "Instalment plans available",
  ];
  const { isPending, error, data } = useQuery(["product"], () => {
    return axios.get(`product/filter`, {
      params: {
        filter: "detailsPage",
        equal: "/nvq-level-6-occupational-health-safety-practice",
      },
    });
  });

  const { addToCart } = useCart();
  const navigate = useNavigate();
  const AddToCart = (data) => {
    const { _id, name, price } = data;
    addToCart(
      {
        _id,
        name,
        price,
        vat: data.productCategory.vat,
        quantity: 1,
        type: data.productCategory.type,
      },
      0
    );
    navigate("/checkout");
  };

  return (
    <Flex
      direction={useBreakpointValue({ base: "column", md: "row" })}
      bg="white"
      p={useBreakpointValue({ base: 2, md: 4 })}
      justify="space-between"
      px={useBreakpointValue({ base: 6, md: "4rem" })}
      maxW="150ch"
      gap={useBreakpointValue({ base: 2, md: 5 })}
      mx="auto"
      position="relative"
      minHeight="100vh"
    >
      <VStack
        spacing={4}
        align="stretch"
        w={useBreakpointValue({ base: "100%", md: "55%" })}
        px={useBreakpointValue({ base: 0, md: 10 })}
        my={useBreakpointValue({ base: 4, md: "3rem" })}
      >
        <Breadcrumb
          mb={5}
          fontSize="xs"
          fontWeight={500}
          spacing="4px"
          separator={<MdChevronRight color="gray.500" />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink href="/">Home</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href="/nvq-health-safety">
              NVQ Health and Safety
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href="/nvq-level-6-occupational-health-safety-practice">
              Health and Safety Practice
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Heading as="h1" size="lg" color="#941C1C" mb={3}>
          NVQ Level 6 Diploma in Occupational Health and Safety Practice
        </Heading>
        <UnorderedList
          style={{
            listStyle: "circle",
            color: "black",
            marginBottom: 15,
          }}
        >
          {points.map((point, index) => (
            <ListItem key={index}>
              <Text>{point}</Text>
            </ListItem>
          ))}
        </UnorderedList>
        <Text fontSize="xl" fontWeight="600">
          £{data?.data.result[0].price} + VAT
        </Text>
        <Flex
          direction={useBreakpointValue({ base: "column", md: "row" })}
          justify="space-between"
          align="center"
          gap={useBreakpointValue({ base: 2, md: 5 })}
        >
          <Button
            onClick={() => AddToCart(data?.data.result[0])}
            w={useBreakpointValue({ base: "100%", md: "50%" })}
            borderRadius="2rem"
            color="white"
            bg="#941C1C"
            _hover={{ filter: "brightness(105%)" }}
            transition="all ease-in-out 200ms"
          >
            <Icon as={FaShoppingCart} mr={3} />
            Book Now
          </Button>
          <Button
            w={useBreakpointValue({ base: "100%", md: "50%" })}
            borderRadius="2rem"
            color="#941C1C"
            border="1px solid #941C1C"
            bg="transparent"
            _hover={{ filter: "brightness(105%)" }}
            transition="all ease-in-out 200ms"
          >
            <Icon as={Download} mr={3} />
            <ChakraLink
              href="https://www.proqualab.com/wp-content/uploads/2018/03/ProQual-Level-6-NVQ-Diploma-in-Occupational-HS-Practice.pdf"
              isExternal
            >
              Course Specifications
            </ChakraLink>
          </Button>
        </Flex>
        <VStack mt={6} textAlign="left" justify="start" align="start">
          <Text fontWeight="bold">About this NVQ assessment</Text>
          <Text>
            NVQ Level 6 Diploma in Occupational Health and Safety Practice
            qualification is aimed at candidates who are responsible for
            developing and applying health and safety procedures day-to-day in
            their organisation. They may be a staff member, supervisor or
            manager looking to improve their knowledge and skills. You must have
            previous experience in a health and safety role and have
            sufficiently and suitable knowledge before you undertake this NVQ.
          </Text>

          <Text fontWeight="bold">Entry Requirements</Text>
          <Text>
            You must be able to demonstrate that you can meet the skills,
            knowledge and experience requirements of the NVQ before enrolment.
          </Text>

          <Text fontWeight="bold">How long will my NVQ take to complete?</Text>
          <Text>
            An assessment plan will be agreed during your Initial
            assessment/Induction and a planned end date agreed. However, we
            would expect your qualification to be complete with 8 weeks
            depending on the learner’s time availability.
          </Text>

          <Text fontWeight="bold">Assessment contents & units</Text>
          <Text>
            Mandatory Units:
            <UnorderedList>
              <ListItem>Promote a positive health and safety culture</ListItem>
              <ListItem>
                Develop and implement the health and safety policy
              </ListItem>
              <ListItem>
                Develop and implement effective communication systems for health
                and safety information
              </ListItem>
              <ListItem>
                Develop and maintain individual and organisational competence in
                health and safety matters
              </ListItem>
              <ListItem>
                Identify, assess and control health and safety risks
              </ListItem>
              <ListItem>
                Develop and implement proactive monitoring systems for health
                and safety
              </ListItem>
              <ListItem>
                Develop and implement reactive monitoring systems for health and
                safety
              </ListItem>
              <ListItem>
                Develop and implement health and safety emergency response
                systems and procedures
              </ListItem>
              <ListItem>
                Develop and implement health and safety review systems
              </ListItem>
              <ListItem>
                Maintain knowledge of improvements to influence health and
                safety practice
              </ListItem>
            </UnorderedList>
          </Text>

          <Text fontWeight="bold">Industry recognition & benefits</Text>
          <Text>
            NVQ Level 6 Diploma in Occupational Health and Safety Practice
            qualifies the holder to apply for the Construction Skills
            Certification Scheme (CSCS){" "}
            <ChakraLink
              as={RouterLink}
              to={"/detail-page-6"}
              style={{ color: "blue", fontWeight: "bold" }}
            >
              Black CSCS Card
            </ChakraLink>
            NVQ Level 6 Diploma in Occupational Health and Safety Practice
            allows you access to becoming Chartered with IOSH
          </Text>
        </VStack>
      </VStack>
      <ContactForm />
    </Flex>
  );
}

export default NvqHealthAndSafetyDetailPage2;
