import {
  Stack,
  Flex,
  Text,
  Box,
  Heading,
  SimpleGrid,
  useMediaQuery,
  Image,
  Divider,
  useColorModeValue,
  Container,
  Icon,
  Button,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { FcManager } from "react-icons/fc";
import React, { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import Slider from "react-slick";
import { chakra, Stat, StatLabel, StatNumber } from "@chakra-ui/react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import testimonials from "./data/homedb.js";
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaQuoteLeft, FaShoppingCart } from "react-icons/fa";
import { useQuery } from "react-query";
import currency from "currency.js";
import axios from "axios";

function StatsCard({ title, stat, imageSrc, alt }) {
  return (
    <Stat
      px={{ base: 3, md: 3 }}
      py={"2"}
      height={"sm"}
      borderColor={useColorModeValue("gray.800", "gray.500")}
      rounded={"lg"}
      overflow={"hidden"}>
      <Box height={"70%"} textAlign={"center"}>
        <Image
          src={imageSrc}
          alt={alt}
          mb={4}
          mx="auto"
          width={"80%"}
          borderRadius={"0.5rem"}
        />
        <StatLabel
          fontSize={"xl"}
          fontWeight={"bold"}
          mb={3}
          color={"#071952"}
          isTruncated>
          {title}
        </StatLabel>
        <StatNumber fontSize={"md"} fontWeight={"400"} lineHeight={"1.25rem"}>
          {stat}
        </StatNumber>
      </Box>
    </Stat>
  );
}

const Card = ({ heading, description, icon, href, price }) => {
  return (
    <Link to={href}>
      <Box
        maxW={{ base: "full", md: "475px" }}
        w={"full"}
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        p={5}>
        <Stack align={"start"} spacing={2}>
          <Flex
            w={16}
            h={16}
            align={"center"}
            justify={"center"}
            color={"white"}
            rounded={"full"}
            bg={useColorModeValue("gray.100", "gray.700")}>
            {icon}
          </Flex>
          <Box mt={2}>
            <Heading size="md" color="#B31312">
              {heading}
            </Heading>
            <Text mt={2} fontSize={"sm"}>
              {description}
            </Text>
          </Box>
          <Link to={href}>
            <Button
              style={{
                marginTop: "0.5rem",
                padding: "0.25rem 0.5rem",
                color: "white",
                fontSize: "small",
                textDecoration: "none",
                fontWeight: "600",
                backgroundColor: "#121212",
                border: "1px solid #121212",
                borderRadius: "2rem",
              }}>
              {currency(price, { symbol: "£" }).format()} + VAT
            </Button>
          </Link>
        </Stack>
      </Box>
    </Link>
  );
};

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "0.18rem",
        paddingRight: "0.025rem",
        background: "#941C1C",
        width: "fit-content",
        aspectRatio: "1",
        borderRadius: "50%",
      }}
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "0.18rem",
        paddingLeft: "0.025rem",
        background: "#941C1C",
        width: "fit-content",
        aspectRatio: "1",
        borderRadius: "50%",
      }}
      onClick={onClick}
    />
  );
}

const CourseCard = ({ title, price, text, index, imageSrc, url }) => (
  <RouterLink to={url}>
    <VStack
      h={useBreakpointValue({ base: "fit-content", md: "45ch" })}
      flexDir={"column"}
      key={index}
      border={"1px solid #E0E0E0"}
      p={3}
      borderRadius={"0.5rem"}>
      <Image
        src={imageSrc}
        alt={title}
        w={"100%"}
        aspectRatio={"16/9"}
        borderRadius={"0.5rem"}
      />
      <VStack spacing={2} mt={4} textAlign={"left"} align={"left"}>
        <Text fontSize="2xl" fontWeight={"700"} color="#941C1C">
          {title.slice(0, 25)}
        </Text>
        <Text fontSize={"xl"} fontWeight={"700"}>
          {price}
        </Text>
        <Text fontSize={"sm"} color={"gray"}>
          {text}
        </Text>
      </VStack>
    </VStack>
  </RouterLink>
);

export default function Home() {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const { data: data1 } = useQuery(["product1"], () => {
    return axios.get(`product/filter`, {
      params: {
        filter: "title",
        equal:
          "NVQ Level 6 Diploma in Construction Site Management (Construction) – Demolition Pathway",
      },
    });
  });

  const { data: data2 } = useQuery(["product2"], () => {
    return axios.get(`product/filter`, {
      params: {
        filter: "title",
        equal: "NVQ Level 7 Diploma in Construction Senior Management",
      },
    });
  });

  const { data: data3 } = useQuery(["product3"], () => {
    return axios.get(`product/filter`, {
      params: {
        filter: "title",
        equal: "NVQ Level 7 Diploma in Strategic Health & Safety",
      },
    });
  });

  const { data: data4 } = useQuery(["product4"], () => {
    return axios.get(`product/filter`, {
      params: {
        filter: "title",
        equal:
          "NVQ Level 3 Diploma in Occupational Work Supervision (Construction)",
      },
    });
  });

  console.log(data1, data2, data3, data4);
  const cards = [
    {
      title: "CSCS CARD",
      price: "£60 + VAT",
      text: "Elevate Your Construction Career – Let Us Secure Your CSCS Card for You!",
      imageSrc: require("../assests/homePage/image1.png"),
      url: "/cscs-cards",
    },
    {
      title: "Level 1 Award In Health",
      price: "£99 + VAT",
      text: "Secure Your Green Laborer Card – Enroll in Our Level 1 Award Course!",
      imageSrc: require("../assests/homePage/image2.jpg"),
      url: "/level-one-page",
    },
    {
      title: "NVQ Level 7 Senior Construction Site Management",
      price: "£1400 + VAT",
      text: "NVQ Level 7 Diploma in Construction Senior Management is aimed at senior managers in the construction industry working in a wide range of job roles.",
      imageSrc: require("../assests/homePage/image3.jpg"),
      url: "/nvq-level-7",
    },
    {
      title: "CITB Halth and Safety Test",
      price: "£45",
      text: "Book Your CITB Health and Safety Test with Us!",
      imageSrc: require("../assests/homePage/image4.jpg"),
      url: "/citb-health-and-safety-policy",
    },
  ];

  return (
    <>
      {/* Hero Section */}
      <Flex
        w={"100%"}
        overflowX={"hidden"}
        h={"fitcontent"}
        backgroundImage={require("../assests/homePage/hero-image.jpg")}
        backgroundSize={"cover"}
        backgroundPosition={"center center"}>
        <VStack
          py={"8rem"}
          w={"100%"}
          justify={"center"}
          px={useBreakpointValue({ base: 4, md: 8 })}
          bgGradient={
            "linear(to-r, transparent,  blackAlpha.700, transparent)"
          }>
          <Stack maxW={"2xl"} align={"flex-start"} spacing={3}>
            <Text
              w={"100%"}
              textAlign={"center"}
              color={"white"}
              fontWeight={800}
              lineHeight={1.2}
              fontSize={useBreakpointValue({ base: "4xl", md: "5xl" })}>
              Elevate your construction
              <br />
              career with M2HSE
            </Text>
            <Text
              textAlign={"center"}
              fontWeight={500}
              color={"whiteAlpha.800"}
              fontSize={useBreakpointValue({ base: "md", md: "lg" })}>
              A leading provider of professional training renowned for its
              experienced tutors, comprehensive curriculum, and
              industry-recognized certifications.
            </Text>
            <Stack
              mt={5}
              w={"100%"}
              direction={"row"}
              align={"center"}
              justify={"center"}>
              <Link to={"/contact-us"}>
                <Button
                  bg={"#941C1C"}
                  rounded={"full"}
                  color={"white"}
                  minW={"15ch"}
                  pb={"0.1rem"}
                  transition={"all ease-in-out 200ms"}
                  _hover={{ filter: "brightness(105%)" }}>
                  Enquire Now
                </Button>
              </Link>
              <Link to={"/about-us"}>
                <Button
                  bg={"whiteAlpha.900"}
                  rounded={"full"}
                  color={"black"}
                  minW={"15ch"}
                  transition={"all ease-in-out 200ms"}
                  _hover={{ bg: "white" }}>
                  About Us
                </Button>
              </Link>
            </Stack>
          </Stack>
        </VStack>
      </Flex>

      {/* Whats New Section */}
      <Box p={4} mt={10}>
        <Stack spacing={2} as={Container} maxW={"3xl"} textAlign={"center"}>
          <Text
            fontSize={{ base: "xl", sm: "3xl" }}
            fontWeight={"600"}
            color={"#941C1C"}>
            What's New?
          </Text>
          <Text color={"gray.600"} fontSize={{ base: "sm", sm: "md" }}>
            We look forward to welcoming you to our training program and
            supporting your journey to a safer and more prosperous future in
            construction!
          </Text>
        </Stack>

        <Container maxW={"5xl"} mt={12}>
          <Flex flexWrap="wrap" gridGap={6} justify="center">
            <Card
              heading={"NVQ Level 6 Construction Site Management"}
              icon={<Icon as={FcManager} w={10} h={10} />}
              description={
                "The NVQ Level 6 in Construction Site Management is a proficiency-based qualification for current managers working on construction sites who want a recognized qualification."
              }
              href={"/nvq-level-six-building-and--civil-engireering-Pathway"}
              price={data1?.data.result[0].price}
            />
            <Card
              heading={"NVQ L7 Construction Senior Management"}
              icon={<Icon as={FcManager} w={10} h={10} />}
              description={
                "NVQ Level 7 Diploma in Construction Senior Management is aimed at senior managers in the construction industry working in a wide range of job roles."
              }
              href={"/nvq-level-7"}
              price={data2?.data.result[0].price}
            />
            <Card
              heading={"NVQ L7 Strategic Leadership Health and Safety"}
              icon={<Icon as={FcManager} w={10} h={10} />}
              description={
                "The NVQ Level 7 Diploma in Strategic Health & Safety Management and Leadership qualification is aimed at candidates who are responsible for developing and applying health and safety procedures day-to-day in any organisation."
              }
              href={"/Strategic-Health-and-Safety"}
              price={data3?.data.result[0].price}
            />
            <Card
              heading={"NVQ L3 Occupational Work Supervision"}
              icon={<Icon as={FcManager} w={10} h={10} />}
              description={
                "The Level 3 NVQ Diploma in Occupational Work Supervision is designed to assess occupational competence in the workplace. Learners are required to demonstrate their skills, knowledge, and experience in the supervision of their colleagues."
              }
              href={"/nvq-level-three-occupational-supervision"}
              price={data4?.data.result[0].price}
            />
          </Flex>
        </Container>
      </Box>

      <Divider my={6} />

      {/* More Courses Section */}
      <Box
        position={"relative"}
        width={{ base: "100%", md: "80%" }}
        margin={"auto"}
        marginTop={5}
        overflow={"hidden"}
        p={5}
        mx={"auto"}>
        <Text
          fontSize={{ base: "xl", sm: "3xl" }}
          fontWeight={"600"}
          textAlign={"center"}
          mb={8}
          color={"#941C1C"}>
          Check out more courses
        </Text>
        <Box p={"0 1rem"} h={"55ch"}>
          <Slider
            dots
            slidesToShow={useBreakpointValue({ base: 1, md: 3 })}
            slidesToScroll={1}
            speed={500}
            nextArrow={<NextArrow />}
            prevArrow={<PrevArrow />}>
            {cards.map((card, index) => (
              <CourseCard {...card} index={index} />
            ))}
          </Slider>
        </Box>
      </Box>

      <Divider my={6} />

      {/* What M2HSE will do Section */}
      <Stack
        direction={useBreakpointValue({ base: "column", md: "row" })}
        mt={useBreakpointValue({ base: 6, md: 0 })}
        spacing={6}
        justify={"space-between"}
        py={useBreakpointValue({ base: 6, md: 12 })}
        px={useBreakpointValue({ base: 12, md: 24 })}
        width={{ base: "100%", md: "80%" }}
        mx={"auto"}>
        <Stack
          spacing={4}
          w={useBreakpointValue({ base: "100%", md: "50%" })}
          textAlign={useBreakpointValue({ base: "center", md: "left" })}>
          <Text fontSize={"3xl"} fontWeight={600} color={"#941C1C"}>
            M2HSE Works For You
          </Text>
          <Text>
            M2HSE is a leading provider of construction training, committing to
            elevating the skills and knowledge of individuals within the sector,
            setting the standard for professionalism and expertise.
          </Text>
          <Text>
            At M2HSE, we pride ourselves on our team of qualified and
            experienced team who bring a wealth of practical experience to the
            training programs
          </Text>
        </Stack>
        <Image
          w={useBreakpointValue({ base: "100%", md: "40%" })}
          rounded={"md"}
          alt={"feature image"}
          src={
            "https://images.unsplash.com/photo-1554200876-56c2f25224fa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
          }
          objectFit={"cover"}
        />
      </Stack>

      <Divider my={6} />

      {/* Why choose M2HSE Section */}
      <Box
        width={{ base: "100%", md: "80%" }}
        mx={"auto"}
        pt={2}
        px={{ base: 2, sm: 12, md: 17 }}
        mb={"20px"}>
        <chakra.h1
          textAlign={"center"}
          fontSize={"3xl"}
          py={10}
          color={"#941C1C"}
          fontWeight={"600"}>
          Why Choose M2HSE?
        </chakra.h1>
        <SimpleGrid
          w={"85%"}
          mx={"auto"}
          columns={{ base: 1, md: 3 }}
          spacing={{ base: 1, lg: 8 }}>
          <StatsCard
            fontSize={"xl"}
            title={"Proven Success"}
            stat={
              "Explore our track record of successfully trained individuals who have gone on to excel in their construction careers."
            }
            imageSrc={require("../assests/homePage/trophy.jpg")} // Replace with actual image source
            alt={"Image 1"}
          />
          <StatsCard
            title={"Industry Recognition"}
            stat={
              "Our training programs are recognized and respected within the construction industry, adding significant value to the credentials of our participants."
            }
            imageSrc={require("../assests/homePage/networking.jpg")} // Replace with actual image source
            alt={"Image 2"}
          />
          <StatsCard
            title={"Flexible Learning Options"}
            stat={
              "We understand the demands of a career in construction. Our flexible learning options, including online and on-site training, cater to a variety of schedules and preferences."
            }
            imageSrc={require("../assests/homePage/remote.jpg")} // Replace with actual image source
            alt={"Image 3"}
          />
        </SimpleGrid>
      </Box>

      <Divider my={6} />

      {/* Testimonials */}
      <Box
        w={useBreakpointValue({ base: "100%", md: "85%" })}
        textAlign={useBreakpointValue({ base: "center", md: "left" })}
        mx="auto">
        <chakra.h1
          textAlign={"center"}
          fontSize={"3xl"}
          color={"#941C1C"}
          fontWeight={"600"}>
          Testimonials
        </chakra.h1>
        <Text textAlign={"center"}>
          Read what our users are saying about us!
        </Text>
      </Box>
      <Box
        w={useBreakpointValue({ base: "100%", md: "75%" })}
        mx={"auto"}
        px={useBreakpointValue({ base: 8, md: 24 })}>
        <Carousel showArrows={false} infiniteLoop>
          {testimonials.map((testimonial, index) => (
            <VStack
              key={index}
              align={"center"}
              bg="#FFECDA"
              color={"1A1A1A"}
              borderRadius={"lg"}
              boxShadow="lg"
              mx={"auto"}
              py={8}
              px={10}
              my={8}>
              <Icon
                as={FaQuoteLeft}
                w={10}
                h={10}
                color={"#941C1C"}
                mr={"auto"}
              />
              <Text mt={2}>{testimonial.testimonial}</Text>
              <Text
                fontSize="lg"
                textAlign={"right"}
                fontWeight="500"
                color={"#941C1C"}
                ml={"auto"}>
                ~ {testimonial.name}
              </Text>
            </VStack>
          ))}
        </Carousel>
      </Box>
    </>
  );
}
