import React, { useState } from "react";
import {
  Box,
  Heading,
  Icon,
  Text,
  HStack,
  VStack,
  Flex,
  Button,
  Grid,
  GridItem,
  Divider,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  useBreakpointValue,
  Image,
  Spinner,
} from "@chakra-ui/react";
import { FaShoppingCart } from "react-icons/fa";
import { Link as RouterLink } from "react-router-dom";
import { MdChevronRight } from "react-icons/md";
import axios from "axios";
import { API_BASE_URL } from "../../src/config/serverApiConfig";
import { useCart } from "../pages/cart/addToCart";
import { useQuery } from "react-query";
axios.defaults.baseURL = API_BASE_URL;

const CourseCard = ({
  _id,
  name,
  points,
  price,
  detailsPage,
  updateCartCount,
  productCategory,
  photo,
}) => {
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    addToCart(
      { _id, name, price, quantity: 1, vat: productCategory.vat },
      updateCartCount
    );
  };
  return (
    <Flex
      direction="column"
      justifyContent="space-between"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p={6}
      bg="white"
      maxWidth="300px"
      mb={4}
    >
      <RouterLink
        state={{
          _id: _id,
          name: name,
          price: price,
          vat: productCategory.vat,
          path: detailsPage,
        }}
        to={detailsPage}
        ml={2}
        style={{ textDecoration: "none", fontSize: "md" }}
      >
        <Image src={photo} maxH="200px" objectFit="cover" mb={4} />{" "}
        {/* Adjust image size */}
        <Text fontSize="lg" fontWeight="bold" mb={4} color="#121212">
          {name}
        </Text>
        <Flex direction="column" flex="1" style={{ overflowY: "hidden" }}>
          {detailsPage && (
            <RouterLink
              state={{
                _id: _id,
                name: name,
                price: price,
                vat: productCategory.vat,
                path: detailsPage,
              }}
              to={detailsPage}
              ml={2}
              style={{ textDecoration: "underline", fontSize: "md" }}
            >
              Card Details
            </RouterLink>
          )}
        </Flex>
        <Divider my={2} />
      </RouterLink>
      <Flex justifyContent="space-between" align="center">
        <Text fontWeight="600" fontSize="sm">
          £{price} + VAT
        </Text>
        <Button
          onClick={handleAddToCart}
          borderRadius="2rem"
          bg="#941C1C"
          color="white"
          fontWeight="500"
          _hover={{ filter: "brightness(105%)" }}
        >
          <Icon as={FaShoppingCart} mr={2} />
          Add to Cart
        </Button>
      </Flex>
    </Flex>
  );
};

const CSCSPage = () => {
  const [activeTag, setActiveTag] = useState("All");

  const tags = ["All" /* Add other tags if needed */];
  const { isPending, error, data } = useQuery(["product"], () => {
    const categories = JSON.parse(localStorage.getItem("category"));
    return axios.get(`product/filter`, {
      params: {
        filter: "productCategory",
        equal: `${categories["Apply for CSCS Cards"]}`,
      },
    });
  });
  return (
    <>
      <Flex direction="column" bg="white" p={8} maxW="150ch" mx="auto">
        <Breadcrumb
          mb={5}
          fontSize="xs"
          fontWeight={500}
          spacing="4px"
          separator={<MdChevronRight color="gray.500" />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink href="/">Home</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href="/cscs-cards">CSCS Cards</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <VStack paddingTop="10px" spacing="2" alignItems="flex-start">
          <Heading color="#941C1C" fontSize={"md"}>
            Why Choose Us for Your CSCS Card Application?
          </Heading>
          <Text as="p" fontSize="sm">
            Our streamlined process ensures you enjoy the benefits without the
            hassle.
          </Text>
          <Heading color="#941C1C" fontSize={"md"}>
            Benefits of Our CSCS Card Service
          </Heading>
          <Text as="p" fontSize="sm">
            Effortless Application: We take care of the entire application
            process, allowing you to focus on what you do best – your
            work.Expert Guidance: Unsure about the right card for your role? Our
            experienced team is here to guide you through the selection process.
            Time-Efficient Processing: No more waiting. We expedite the
            application process to get your card to you as quickly as possible.
            Peace of Mind: Let professionals handle the paperwork and
            documentation, ensuring accuracy and compliance.
          </Text>
          <Heading color="#941C1C" fontSize={"md"}>
            How It Works
          </Heading>
          <Text as="p" fontSize="sm">
            Place Your Order: Make a secure online payment or Contact Us if you
            need more information before you pay and leave the rest to us.
            Provide Information: Our team of experts will call you to get the
            required information for application. Sit Back and Relax: We handle
            the entire application process, from submission to approval. Receive
            Your Card: Once approved, your CSCS card will be dispatched promptly
            to your designated address. For any questions or assistance, contact
            us – we’re committed to making your construction journey smoother
            and more successful.
          </Text>
        </VStack>

        <Text
          fontSize="3xl"
          fontWeight="600"
          textAlign="left"
          color="#941C1C"
          mt={2}
        >
          Available Cards
        </Text>
        <HStack mt={2} mb={8} overflow="hidden">
          {tags.map((tag, index) => (
            <Box
              whiteSpace="nowrap"
              fontSize="sm"
              key={index}
              border="1px solid #121212"
              borderRadius="2rem"
              px={3}
              bg={tag === activeTag ? "black" : "transparent"}
              color={tag === activeTag ? "white" : "black"}
              onClick={() => setActiveTag(tag)}
              cursor="pointer"
            >
              {tag}
            </Box>
          ))}
        </HStack>
        {!data ? (
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
            position={"fixed"}
            left={"50%"}
            top={"50%"}
          />
        ) : (
          <br />
        )}
        <Grid
          templateColumns={useBreakpointValue({
            base: "repeat(1, 1fr)",
            md: "repeat(3, 1fr)",
          })}
          gap={6}
        >
          {data?.data.result.map((card, index) => (
            <GridItem key={index}>
              <CourseCard {...card} />
            </GridItem>
          ))}
        </Grid>
      </Flex>
    </>
  );
};

export default CSCSPage;
