import React from "react";
import {
  Box,
  Container,
  SimpleGrid,
  Stack,
  Text,
  Image,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={"500"} fontSize={"lg"} mb={2}>
      {children}
    </Text>
  );
};

const Footer = () => {
  return (
    <Box bg={"#212121"} color={"white"}>
      <Container as={Stack} maxW={"8xl"} py={10}>
        <SimpleGrid
          templateColumns={{ sm: "1fr 1fr", md: "2fr 1fr 1fr 2fr" }}
          spacing={8}>
          <Stack spacing={6}>
            <Box>
              <Text>
                Address:M2HSE Training Ltd.
                <br /> Ceme Business Campus, <br /> Commercial 1, Marsh Way,{" "}
                <br /> Rainham, RM13 8EU <br /> Phone: 02080-599944
                <br /> Email: office@m2hse.co.uk
              </Text>
            </Box>
            <Text fontSize={"sm"}>COPYRIGHT © 2024 M2HSE</Text>
            <Text>Company Registration No: 12804208</Text>
            <Text>VAT Number 357511104</Text>
          </Stack>

          <Stack align={"flex-start"}>
            <ListHeader>Company</ListHeader>
            <Box as="a" href={"../about-us"}>
              About us
            </Box>
            <Box as="a" href={"../contact-us"}>
              Contact us
            </Box>
          </Stack>
          <Stack align={"flex-start"}>
            <ListHeader>Support</ListHeader>
            <Box as="a" href={"../privacy-terms"}>
              Terms of Service
            </Box>
            <Box as="a" href={"../privacy-policy"}>
              Privacy Policy
            </Box>
            <Box as="a" href={"../privacy-cookies"}>
              Cookies Policy
            </Box>
          </Stack>
          <Text>
            M2HSE is not a part of, <br /> or associated with CSCS, CPCS or
            CITB. <br /> If you require further information on CSCS please
            <br />{" "}
            <Link
              style={{ color: "blue", fontWeight: "bold" }}
              to="https://www.cscs.uk.com/">
              click here.
            </Link>
          </Text>
          <Image src={require("../assests/footer/proQual.png")} />
        </SimpleGrid>
      </Container>
    </Box>
  );
};

export default Footer;
