import {
  Heading,
  Text,
  VStack,
  Button,
  Icon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  useBreakpointValue,
  Flex,
} from "@chakra-ui/react";
import { FaShoppingCart } from "react-icons/fa";
import React, { useState } from "react";

import { MdChevronRight } from "react-icons/md";
import AOS from "aos";
import ContactForm from "../nvqPages/course-details/contactForm";
import axios from "axios";
import { useQuery } from "react-query";
import { useCart } from "../cart/addToCart";

function LevelOnePage() {
  React.useEffect(() => {
    AOS.init({
      duration: 1200,
      easing: "ease-in-out-back",
    });
  }, []);

  const { addToCart } = useCart();

  const [price, setPrice] = useState(168);
  const { isPending, error, data } = useQuery(["product"], () => {
    const categories = JSON.parse(localStorage.getItem("category"));
    return axios.get(`product/filter`, {
      params: {
        filter: "productCategory",
        equal: `${categories["NVQ Level 1"]}`,
      },
    });
  });

  const AddToCart = () => {
    if (data?.data) {
      const { _id, name, price, productCategory } = data?.data.result[0];
      addToCart({
        _id,
        name,
        price,
        vat: productCategory.vat,
        quantity: 1,
        type: productCategory.type,
      });
    }
  };

  return (
    <Flex
      direction={["column", "row"]}
      bg="white"
      p={[2, 4]}
      justify="space-between"
      px={[6, "4rem"]}
      maxW="150ch"
      gap={[2, 5]}
      mx="auto"
      position="relative"
      minH="100vh"
    >
      <VStack
        spacing={4}
        align="stretch"
        w={["100%", "55%"]}
        px={[0, 10]}
        my={[4, "3rem"]}
      >
        <Breadcrumb
          mb={5}
          fontSize="xs"
          fontWeight={500}
          spacing="4px"
          separator={<MdChevronRight color="gray.500" />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink href="/">Home</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href="/level-one-page">NVQ Level 1</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Text opacity="60%" id="trigger-right" text="sm" mb="-0.5rem">
          Level 1 Award Health & Safety
        </Text>
        <Heading as="h1" size="lg" color="#941C1C">
          Award Health & Safety
        </Heading>
        <Text fontSize="xl" fontWeight="600">
          £{data?.data.result[0].price} + VAT
        </Text>
        <Flex
          direction={["column", "row"]}
          justify="space-between"
          align="center"
          gap={[2, 5]}
        >
          <Button
            w={["100%", "50%"]}
            borderRadius="2rem"
            color="white"
            bg="#941C1C"
            _hover={{ filter: "brightness(105%)" }}
            transition="all ease-in-out 200ms"
            onClick={AddToCart}
          >
            <Icon as={FaShoppingCart} mr={3} />
            Add to Cart
          </Button>
        </Flex>
        <VStack mt={6} textAlign="left" justify="start" align="start">
          <Text fontWeight="bold">Foundational Knowledge:</Text>
          <Text>
            Our Level 1 Award covers essential health and safety principles,
            hazard recognition, emergency procedures, and the proper use of
            Personal Protective Equipment (PPE).
          </Text>

          <Text fontWeight="bold">Industry Recognition:</Text>
          <Text>
            The CITB Health and Safety Test assesses your understanding of
            construction safety practices, ensuring you’re well-equipped to
            contribute to a safe work environment.
          </Text>

          <Text fontWeight="bold">Career Advancement:</Text>
          <Text>
            These certifications not only fulfill Green Labourer Card
            requirements but also boost your employability, opening doors to a
            spectrum of construction opportunities.
          </Text>

          <Text fontWeight="bold">How to Get Started:</Text>

          <Text>
            - Enroll in our Level 1 Award to build a strong foundation in
            construction safety. <br />
            - Book CITB Health and Safety Test: Schedule your CITB Health and
            Safety Test to showcase your commitment to safety practices in the
            construction field. <br />
          </Text>
          <Text>
            <Text fontWeight="bold">We’re Here to Assist:</Text>
            Have questions or need assistance with the enrollment process? Our
            dedicated team is here to help! Feel free to contact us, and we’ll
            guide you through every step of the way.
          </Text>
          <Text fontWeight="bold">Secure Your Future in Construction:</Text>
          <Text>
            Don’t miss out on this opportunity to elevate your skills and safety
            knowledge. Enroll in our Level 1 Award Course and CITB Health and
            Safety Test today and set yourself on the path to a safer and more
            successful construction career.
          </Text>
          <Text fontWeight="bold">
            Enroll Now for Level 1 Award & CITB Test
          </Text>
          <Text>
            We look forward to welcoming you to our training program and
            supporting your journey to a safer and more prosperous future in
            construction.
          </Text>
        </VStack>
      </VStack>
      <ContactForm />
    </Flex>
  );
}

export default LevelOnePage;
