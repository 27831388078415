import {
  Heading,
  Text,
  VStack,
  Button,
  UnorderedList,
  Link,
  ListItem,
  Icon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  useBreakpointValue,
  Flex,
} from "@chakra-ui/react";
import { FaShoppingCart } from "react-icons/fa";
import { MdOutlineFileDownload as Download } from "react-icons/md";
import React from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { MdChevronRight } from "react-icons/md";
import AOS from "aos";
import ContactForm from "../contactForm";
import { useCart } from "../../../cart/addToCart";
import { useNavigate } from "react-router-dom";

function CourseDetail22Page() {
  React.useEffect(() => {
    AOS.init({
      duration: 1200,
      easing: "ease-in-out-back",
    });
  }, []);
  const { addToCart } = useCart();

  const points = [
    "All remotely online portfolio",
    "Fast track completion 8 weeks",
    "Instalment plans available",
    "Leading to blue CSCS Card",
  ];
  const { isPending, error, data } = useQuery(["product"], () => {
    return axios.get(`product/filter`, {
      params: {
        filter: "detailsPage",
        equal: "/nvq-level-two-Plastering-occupation",
      },
    });
  });
  const navigate = useNavigate();
  const AddToCart = (data) => {
    const { _id, name, price } = data;
    addToCart(
      {
        _id,
        name,
        price,
        vat: data.productCategory.vat,
        quantity: 1,
        type: data.productCategory.type,
      },
      0
    );
    navigate("/checkout");
  };
  return (
    <Flex
      direction={useBreakpointValue({ base: "column", md: "row" })}
      bg="white"
      p={useBreakpointValue({ base: 2, md: 4 })}
      justify={"space-between"}
      px={useBreakpointValue({ base: 6, md: "4rem" })}
      maxW={"150ch"}
      gap={useBreakpointValue({ base: 2, md: 5 })}
      mx="auto"
      position="relative"
      minHeight="100vh"
    >
      <VStack
        spacing={4}
        align="stretch"
        w={useBreakpointValue({ base: "100%", md: "55%" })}
        px={useBreakpointValue({ base: 0, md: 10 })}
        my={useBreakpointValue({ base: 4, md: "3rem" })}
      >
        <Breadcrumb
          mb={5}
          fontSize={"xs"}
          fontWeight={500}
          spacing="4px"
          separator={<MdChevronRight color="gray.500" />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink href="/">Home</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href="/level-two-page">NVQ Level 2</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href="/nvq-level-two-Plastering-occupation">
              Plastering
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Heading as="h1" size="lg" color="#941C1C" mb={3}>
          NVQ Level 2 Diploma in Plastering
        </Heading>
        <UnorderedList
          style={{
            listStyle: "circle",
            color: "black",
            marginBottom: 15,
          }}
        >
          {points.map((point, index) => (
            <ListItem key={index}>
              <Text>{point}</Text>
            </ListItem>
          ))}
        </UnorderedList>
        <Text fontSize={"xl"} fontWeight={"600"}>
          £{data?.data.result[0].price} + VAT
        </Text>
        <Flex
          direction={useBreakpointValue({ base: "column", md: "row" })}
          justify={"space-between"}
          align={"center"}
          gap={useBreakpointValue({ base: 2, md: 5 })}
        >
          <Button
            onClick={() => {
              AddToCart(data?.data.result[0]);
            }}
            w={useBreakpointValue({ base: "100%", md: "50%" })}
            borderRadius={"2rem"}
            color={"white"}
            bg={"#941C1C"}
            _hover={{ filter: "brightness(105%)" }}
            transition={"all ease-in-out 200ms"}
          >
            <Icon as={FaShoppingCart} mr={3} />
            Book Now
          </Button>
          <Button
            w={useBreakpointValue({ base: "100%", md: "50%" })}
            borderRadius={"2rem"}
            color={"#941C1C"}
            border={"1px solid #941C1C"}
            bg="transparent"
            _hover={{ filter: "brightness(105%)" }}
            transition={"all ease-in-out 200ms"}
          >
            <Icon as={Download} mr={3} />
            <Link
              href="https://www.proqualab.com/qualifications/keyword/plastering/"
              isExternal
            >
              Course Specifications
            </Link>
          </Button>
        </Flex>
        <VStack mt={6} textAlign={"left"} justify={"start"} align={"start"}>
          <Text fontWeight="bold">Qualification Overview</Text>
          <Text>
            The aim of this qualification is to recognise the knowledge, skills
            and competence of individuals who work in this specialised area of
            the construction industry. This qualification enables learners to
            gain recognition for their skills and the potential to take on more
            responsibility in the workplace
          </Text>
          <Text>
            NVQ Level 2 in Plastering qualification provides a nationally
            recognised qualification for those working in the construction
            industry who want to specialise in plastering.
          </Text>

          <Text fontWeight="bold">Entry Requirements</Text>
          <Text>
            You must be able to demonstrate that you can meet the skills,
            knowledge and experience requirements of the NVQ before enrolment.
          </Text>

          <Text fontWeight="bold">How long will my NVQ take to complete?</Text>
          <Text>
            An assessment plan will be agreed during your Initial
            assessment/Induction and a planned end date agreed. However, we
            would expect your qualification to be complete with 8 weeks
            depending on the learner’s time availability.
          </Text>

          <Text fontWeight="bold">Qualification Structure</Text>
          <Text>
            - Pathway 1: Level 2 NVQ Diploma in Plastering (Solid)
            <br />
            - Pathway 2: Level 2 NVQ Diploma in Plastering (Fibrous)
            <br />- Pathway 3: Level 2 NVQ Diploma in Plastering (Heritage
            Plastering – Solid)
            <br />- Pathway 4: Level 2 NVQ Diploma in Plastering (Heritage
            Plastering – Fibrous)
          </Text>

          <Text fontWeight="bold">Industry recognition & benefits</Text>
          <Text>
            NVQ Level 2 in Plastering qualifies the holder to apply for the
            Construction Skills
            <Link
              to={"/detail-page-3"}
              style={{ color: "blue", fontWeight: "bold" }}
            >
              Blue CSCS Card
            </Link>
          </Text>
        </VStack>
      </VStack>
      <ContactForm />
    </Flex>
  );
}

export default CourseDetail22Page;
