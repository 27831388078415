import {
  Heading,
  Text,
  VStack,
  Button,
  UnorderedList,
  ListItem,
  Icon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  useBreakpointValue,
  Flex,
} from "@chakra-ui/react";
import { FaShoppingCart } from "react-icons/fa";
import React, { useEffect, useState } from "react";
import { MdChevronRight } from "react-icons/md";
import { useQuery } from "react-query";
import ContactForm from "./course-details/contactForm";
import { useCart } from "../cart/addToCart";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

function NVQLevel7Page() {
  const points = [
    "Remotely – ONLINE PORTFOLIO",
    "FAST TRACK COMPLETION 8-12 weeks",
    "Instalment payment plans available",
    "Leading to BLACK CSCS Card",
    "Access to MCIOB",
  ];
  const { addToCart } = useCart();

  const { isPending, error, data } = useQuery(["product"], () => {
    return axios.get(`product/filter`, {
      params: {
        filter: "detailsPage",
        equal: "/nvq-level-7",
      },
    });
  });
  const navigate = useNavigate();
  const AddToCart = (data) => {
    const { _id, name, price } = data;
    addToCart(
      {
        _id,
        name,
        price,
        vat: data.productCategory.vat,
        quantity: 1,
        type: data.productCategory.type,
      },
      0
    );
    navigate("/checkout");
  };
  return (
    <Flex
      direction={useBreakpointValue({ base: "column", md: "row" })}
      bg="white"
      p={useBreakpointValue({ base: 2, md: 4 })}
      justify="space-between"
      px={useBreakpointValue({ base: 6, md: "4rem" })}
      maxW="150ch"
      gap={useBreakpointValue({ base: 2, md: 5 })}
      mx="auto"
      position="relative"
      minHeight="100vh"
    >
      <VStack
        spacing={4}
        align="stretch"
        w={useBreakpointValue({ base: "100%", md: "55%" })}
        px={useBreakpointValue({ base: 0, md: 10 })}
        my={useBreakpointValue({ base: 4, md: "3rem" })}
      >
        <Breadcrumb
          mb={5}
          fontSize="xs"
          fontWeight={500}
          spacing="4px"
          separator={<MdChevronRight color="gray.500" />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink href="/">Home</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href="/nvq-level-7">
              Construction Senior Management
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Text opacity="60%" id="trigger-right" text="sm" mb="-0.5rem">
          Level 7 NVQ Diploma in Construction Senior Management
        </Text>
        <Heading as="h1" size="lg" color="#941C1C" mb={3}>
          Construction Senior Management
        </Heading>
        <UnorderedList
          style={{
            listStyle: "circle",
            color: "black",
            marginBottom: 15,
          }}
        >
          {points.map((point, index) => (
            <ListItem key={index}>
              <Text>{point}</Text>
            </ListItem>
          ))}
        </UnorderedList>
        <Text fontSize="xl" fontWeight="600">
          £{data?.data.result[0].price} + VAT
        </Text>
        <Flex
          direction={useBreakpointValue({ base: "column", md: "row" })}
          justify={useBreakpointValue({ base: "center", md: "space-between" })}
          align="center"
          gap={useBreakpointValue({ base: 2, md: 5 })}
        >
          <Button
            onClick={() => {
              AddToCart(data?.data.result[0]);
            }}
            w={useBreakpointValue({ base: "100%", md: "50%" })}
            borderRadius="2rem"
            color="white"
            bg="#941C1C"
            _hover={{ filter: "brightness(105%)" }}
            transition="all ease-in-out 200ms"
          >
            <Icon as={FaShoppingCart} mr={3} />
            Book Now
          </Button>
        </Flex>
        <VStack mt={6} textAlign="left" justify="start" align="start">
          <Text>
            NVQ Level 7 Diploma in Construction Senior Management is aimed at
            senior managers in the construction industry working in a wide range
            of job roles. You will be expected to collect a variety of evidence
            from your workplace which can prove your proficiency, including
            technical knowledge and producing technical plans for projects, as
            well as managing resources and overcoming problems. You must already
            have experience and knowledge of managing construction projects and
            teams of people.
          </Text>
          <Text fontWeight="bold">Entry Requirements</Text>
          <Text>
            You must be able to demonstrate that you can meet the skills,
            knowledge and experience requirements of the NVQ before enrolment.
          </Text>
          <Text fontWeight="bold">How long will my NVQ take to complete?</Text>
          <Text>
            An assessment plan will be agreed during your Initial
            assessment/Induction and a planned end date agreed. However, we
            would expect your qualification to be complete with 8 weeks
            depending on the learners time availability.
          </Text>
          <Heading size="md" mb={2}>
            Completion of Units
          </Heading>
          <Text>
            Learners must complete all units in group A to achieve 59 credits
            and then select units from two of the three optional groups B1, B2
            and B3 to achieve a minimum total of 144 credits.
          </Text>
          <Text fontWeight="bold">Assessment contents & units</Text>
          <Heading fontSize="lg">A – Mandatory units</Heading>
          Credit value required: minimum 59.
          <UnorderedList>
            <ListItem>A/600/6828 - Manage teams in construction</ListItem>

            <ListItem>
              T/600/6830 – Lead and participate in meetings in construction
              management
            </ListItem>

            <ListItem>
              A/600/6831 – Provide ethical advice, judgement and service in
              construction management
            </ListItem>

            <ListItem>
              J/600/6833 – Develop self and others in construction management
            </ListItem>
          </UnorderedList>
          <Text fontWeight="bold">Industry recognition & benefits</Text>
          <Text>
            NVQ Level 7 Diploma in Construction Senior Management qualifies the
            holder to apply for the{" "}
            <Link to="/detail-page-6" color="blue.500" isExternal>
              Black CSCS Card for Managers
            </Link>
          </Text>
        </VStack>
      </VStack>
      <ContactForm />
    </Flex>
  );
}

export default NVQLevel7Page;
