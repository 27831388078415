import { Box, Heading, Text } from "@chakra-ui/layout";
import { Button } from "antd";
import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PaymentSuccess = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const parsedUrl = new URL(window.location.href);

    // Extract the search parameters
    const searchParams = new URLSearchParams(parsedUrl.search);

    // Iterate over the key-value pairs

    axios
      .put("/orderDetails/update-orders", searchParams)
      .then((res) => {
        axios
          .post("/invoice/mail", { id: res.data.result._id })
          .then((response) => {
            console.log(response.data);
          })
          .catch((err) => {
            console.log(err);
          });
        localStorage.removeItem("client");
        localStorage.removeItem("cart");
        localStorage.removeItem("ids");
      })
      .catch((err) => console.log(err));
  }, []);
  const handleBackToHome = () => {
    navigate("/");
  };
  return (
    <div>
      <Box textAlign="center" mt="20">
        <Heading color="green.500" mb="4">
          Payment Successful
        </Heading>
        <Text fontSize="xl">Thank you for your payment!</Text>
        <Button colorScheme="green" onClick={handleBackToHome}>
          Back to Home
        </Button>
      </Box>
    </div>
  );
};

export default PaymentSuccess;
