import React from "react";
import axios from "axios";
import { useMutation } from "react-query";
import { API_BASE_URL } from "../../../config/serverApiConfig";
import { useForm, ValidationError } from "@formspree/react";

import {
  Button,
  Input,
  Text,
  Textarea,
  VStack,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

axios.defaults.baseURL = API_BASE_URL;

const ContactForm = () => {
  const navigate = useNavigate();

  const [state, handleSubmit] = useForm("mjvnazgy");

  const useCreatePerson = async ({
    firstname,
    lastname,
    email,
    phone,
    message,
  }) => {
    const response = await axios.post("people/create", {
      firstname,
      lastname,
      email,
      phone,
      message,
    });
    return response.data;
  };

  const { isLoading, isError, error, data, mutate } =
    useMutation(useCreatePerson);

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    const firstname = e.target.elements.firstname.value;
    const lastname = e.target.elements.lastname.value;
    const email = e.target.elements.email.value;
    const phone = e.target.elements.phone.value;
    const message = e.target.elements.message.value;

    try {
      await mutate({ firstname, lastname, email, phone, message });
    } catch (error) {
      console.error(error);
    }
  };

  const toast = useToast();
  const handleCombinedSubmit = async (e) => {
    await handleSubmit(e); // Trigger useForm's handleSubmit
    toast({
      title: "Success",
      description: "Form submitted successfully",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    await handleSubmitForm(e);
    e.target.reset();
    navigate("/thank-you");
  };

  return (
    <VStack
      w={useBreakpointValue({ base: "100%", md: "45%" })}
      shadow={"0px 0px 4px 0px rgba(0,0,0,0.15)"}
      borderRadius={"0.75rem"}
      px={8}
      pt={4}
      pb={8}
      position="sticky"
      top="25%"
      zIndex={999}
      right={8}
      h={"fit-content"}
      mt={useBreakpointValue({ base: 2, md: 10 })}
      mb={useBreakpointValue({ base: 4, md: 0 })}>
      <Text w="100%" textAlign={"left"} fontSize={"2xl"} fontWeight={"700"}>
        Enquire Now
      </Text>
      <Text w="100%" opacity={"70%"} mt={"-0.5rem"}>
        Get to know more about our courses!
      </Text>
      <form onSubmit={handleCombinedSubmit}>
        <Input
          mt={3}
          type={"text"}
          id="firstname"
          name="firstName"
          placeholder="First name"
          border="1px ridge"
          borderColor={"blackAlpha.400"}
          required
        />
        <Input
          mt={3}
          type={"text"}
          id="lastname"
          name="lastName"
          placeholder="Last name"
          border="1px ridge"
          borderColor={"blackAlpha.400"}
          required
        />
        <Input
          mt={3}
          id="email"
          type={"email"}
          name="email"
          border="1px ridge"
          placeholder="Email address"
          borderColor={"blackAlpha.400"}
          required
        />
        <ValidationError prefix="Email" field="email" errors={state.errors} />
        <Input
          mt={3}
          type={"phone"}
          id="phone"
          name="phone"
          border="1px ridge"
          placeholder="Phone Number"
          borderColor={"blackAlpha.400"}
          required
        />
        <Textarea
          mt={3}
          id="message"
          name="message"
          resize={"vertical"}
          placeholder="Message"
          borderColor={"blackAlpha.400"}
          mb={3}
        />
        <Button
          mt={3}
          w="100%"
          bg={"#941C1C"}
          color={"white"}
          _hover={{ filter: "brightness(105%)" }}
          transition={"all ease-in-out 300ms"}
          disabled={state.submitting}
          type="submit">
          Send
        </Button>
      </form>
    </VStack>
  );
};

export default ContactForm;
