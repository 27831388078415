import React, { useState } from "react";
import {
  Flex,
  Text,
  Button,
  Grid,
  GridItem,
  UnorderedList,
  ListItem,
  Divider,
  Icon,
  HStack,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  useBreakpointValue,
  Spinner,
} from "@chakra-ui/react";
import { FaShoppingCart } from "react-icons/fa";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { MdChevronRight } from "react-icons/md";
import axios from "axios";
import { API_BASE_URL } from "../../src/config/serverApiConfig";
import { useCart } from "../pages/cart/addToCart";
import { useQuery } from "react-query";
axios.defaults.baseURL = API_BASE_URL;

const CourseCard = ({
  _id,
  name,
  points,
  price,
  detailsPage,
  description,
  updateCartCount,
  productCategory,
}) => {
  const { addToCart } = useCart();
  const navigate = useNavigate();
  const handleAddToCart = () => {
    addToCart(
      { _id, name, price, quantity: 1, vat: productCategory.vat },
      updateCartCount
    );
    navigate("/checkout");
  };
  return (
    <Flex
      direction="column"
      justifyContent="space-between"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p={6}
      bg={"white"}
      maxWidth="100%" // Set max width to 100% for responsiveness
      h="45ch"
      mb={4}
    >
      <RouterLink
        to={{
          pathname: detailsPage,
        }}
        state={{
          _id: _id,
          name: name,
          price: price,
          vat: productCategory.vat,
          path: detailsPage,
        }}
        style={{ textDecoration: "none" }}
      >
        <Text fontSize="xl" fontWeight="bold" mb={4} color={"#121212"}>
          {name}
        </Text>
        <div style={{ overflowY: "scroll", flex: 1, maxHeight: "200px" }}>
          <Flex direction="column">
            <Text>{description}</Text>
          </Flex>
        </div>
      </RouterLink>
      <Divider my={2} />
      <Flex justifyContent="space-between" align={"center"} wrap="wrap">
        {detailsPage && (
          <Text
            as={RouterLink}
            to={{
              pathname: detailsPage,
            }}
            state={{
              _id: _id,
              name: name,
              price: price,
              vat: productCategory.vat,
              path: detailsPage,
            }}
            ml={2}
            style={{ textDecoration: "underline", fontSize: "md" }}
          >
            Course Details
          </Text>
        )}
        <Text fontWeight={"600"} fontSize={"lg"}>
          £{price} + VAT
        </Text>
        <Button
          onClick={handleAddToCart}
          borderRadius={"2rem"}
          bg={"#941C1C"}
          color={"white"}
          fontWeight={"500"}
          _hover={{ filter: "brightness(105%)" }}
        >
          <Icon as={FaShoppingCart} mr={2} />
          Book Now
        </Button>
      </Flex>
    </Flex>
  );
};

const CITBPage = () => {
  const [activeTag, setActiveTag] = useState("All");

  const tags = ["All" /* Add other tags if needed */];
  const { isPending, error, data } = useQuery(["product"], () => {
    const categories = JSON.parse(localStorage.getItem("category"));
    return axios.get(`product/filter`, {
      params: {
        filter: "productCategory",
        equal: `${categories["CITB Courses"]}`,
      },
    });
  });
  return (
    <Flex direction="column" bg="white" p={8} maxW="150ch" mx="auto">
      <Breadcrumb
        mb={5}
        fontSize="xs"
        fontWeight={500}
        spacing="4px"
        separator={<MdChevronRight color="gray.500" />}
      >
        <BreadcrumbItem>
          <BreadcrumbLink href="/">Home</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink href="/citb-courses">CITB courses</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Text textTransform="uppercase" opacity="60%">
        CITB Courses
      </Text>
      <Text
        fontSize="3xl"
        fontWeight="600"
        textAlign="left"
        color="#941C1C"
        mt={2}
      >
        Available Courses
      </Text>
      <HStack mt={2} mb={8} overflow="hidden">
        {tags.map((tag, index) => (
          <Box
            whiteSpace="nowrap"
            fontSize="sm"
            key={index}
            border="1px solid #121212"
            borderRadius="2rem"
            px={3}
            bg={tag === activeTag ? "black" : "transparent"}
            color={tag === activeTag ? "white" : "black"}
            onClick={() => setActiveTag(tag)}
            cursor="pointer"
          >
            {tag}
          </Box>
        ))}
      </HStack>
      {!data ? (
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
          position={"fixed"}
          left={"50%"}
          top={"50%"}
        />
      ) : (
        <br />
      )}
      <Grid
        templateColumns={useBreakpointValue({
          base: "repeat(1, 1fr)",
          md: "repeat(3, 1fr)",
        })}
        gap={6}
      >
        {data?.data.result.map((card, index) => (
          <GridItem key={index}>
            <CourseCard {...card} />
          </GridItem>
        ))}
      </Grid>
    </Flex>
  );
};

export default CITBPage;
