import {
  Heading,
  Text,
  Link,
  VStack,
  Button,
  UnorderedList,
  ListItem,
  Icon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
} from "@chakra-ui/react";
import { FaShoppingCart } from "react-icons/fa";
import { MdOutlineFileDownload as Download } from "react-icons/md";
import React, { useState } from "react";

import { MdChevronRight } from "react-icons/md";
import ContactForm from "./course-details/contactForm";

function NVQLevel4Page() {
  const points = [
    "Remotely – ONLINE PORTFOLIO",
    "FAST TRACK COMPLETION 6-8 weeks",
    "Instalment payment plans available",
    "Leading to GOLD CSCS Card",
  ];

  return (
    <Flex
      direction={{ base: "column", md: "row" }}
      bg="white"
      p={{ base: 2, md: 4 }}
      justify={"space-between"}
      px={{ base: 6, md: "4rem" }}
      maxW={"150ch"}
      gap={{ base: 2, md: 5 }}
      mx="auto"
      position="relative"
      minHeight="100vh">
      <VStack
        spacing={4}
        align="stretch"
        w={{ base: "100%", md: "55%" }}
        px={{ base: 0, md: 10 }}
        my={{ base: 4, md: "3rem" }}>
        <Breadcrumb
          mb={5}
          fontSize={"xs"}
          fontWeight={500}
          spacing="4px"
          separator={<MdChevronRight color="gray.500" />}>
          <BreadcrumbItem>
            <BreadcrumbLink href="/">Home</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href="/nvq-level-4">NVQ Level 4</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Heading as="h1" size="lg" color="#941C1C" mb={3}>
          NVQ Level 4 Construction Site Supervision
        </Heading>
        <UnorderedList
          style={{
            listStyle: "circle",
            color: "black",
            marginBottom: 15,
          }}>
          {points.map((point, index) => (
            <ListItem key={index}>
              <Text>{point}</Text>
            </ListItem>
          ))}
        </UnorderedList>
        <Text fontSize={"xl"} fontWeight={"600"}>
          £1120.00 + VAT
        </Text>
        <Button
          w={{ base: "100%", md: "50%" }}
          borderRadius={"2rem"}
          color={"white"}
          bg={"#941C1C"}
          _hover={{ filter: "brightness(105%)" }}
          transition={"all ease-in-out 200ms"}>
          <Icon as={FaShoppingCart} mr={3} />
          Book Now
        </Button>
        <VStack mt={6} textAlign={"left"} justify={"start"} align={"start"}>
          <Text>
            The NVQ Level 4 Construction Site Supervision diploma is a
            proficiency-based qualification for people who want a stepping stone
            into construction management roles. It’s aimed at current assistant
            supervisors and assistant managers who are looking to obtain a
            nationally recognised accreditation to prove their competency in
            their field of work and can help to move your career forward. You
            must already be working as a site supervisor in a
            construction-related job including civil engineering, demolition,
            highway maintenance, residential development or conservation.
          </Text>

          <Text fontWeight="bold">Entry Requirements</Text>
          <Text>
            You must be able to demonstrate that you can meet the skills,
            knowledge and experience requirements of the NVQ before enrollment.
          </Text>

          <Text fontWeight="bold">How long will my NVQ take to complete?</Text>
          <Text>
            An assessment plan will be agreed during your Initial
            assessment/Induction and a planned end date agreed. However, we
            would expect your qualification to be complete with 8 weeks
            depending on the learner's time availability.
          </Text>

          <Text fontWeight="bold">Assessment contents & units</Text>
          <Text>
            NVQ level 4 in Construction Site Supervision: Qualification
            Structure
          </Text>

          <Text>
            Mandatory Units
            <br />
            - Maintaining Systems for Health, Safety, Welfare and Environmental
            Protection in the Workplace
            <br />
            - Assessing and Recommending Work Methods for Carrying Out Site
            Operations in the Workplace
            <br />
            -Planning Activities and Resources to Meet Work Requirements in the
            Workplace
            <br />- Co-ordinating and Organising the Control of Work in the
            Workplace
            <br />- Controlling Work Progress Against Agreed Programmes in the
            Workplace
            <br />- Allocating and Monitoring the Use of Plant, Equipment or
            Machinery in the Workplace
            <br />
            - Developing and Maintaining Good Occupational Working Relationships
            in the Workplace
            <br />- Maintaining Supplies of Materials to Meet Project
            Requirements in the Workplace
            <br />- Implementing Communication Systems for Construction Projects
            in the Workplace
            <br />- Maintaining the Dimensional Accuracy of Work in the
            Workplace
            <br />- Controlling Work Against Agreed Standards in the Workplace
            <br />- Contributing to the Control of Work Quantities and Costs in
            the Workplace
            <br />- Co-ordinating Preparation Work for Site or Lifting
            Operations in the Workplace
            <br />- Allocating Work and Checking People’s Performance in the
            Workplace
          </Text>

          <Text fontWeight="bold">Industry recognition & benefits</Text>
          <Text>
            The NVQ Diploma in Occupational Work Supervision qualifies the
            holder to apply for the Construction Skills Certification Scheme
            (CSCS) gold card{" "}
            <Link
              href="https://www.cscs.uk.com/card-type/supervisory/"
              color="blue.500"
              isExternal>
              CSCS Card
            </Link>
          </Text>
        </VStack>
      </VStack>
      <ContactForm />
    </Flex>
  );
}

export default NVQLevel4Page;
