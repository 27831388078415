import React, { useState } from "react";
import {
  Box,
  Container,
  Stack,
  Text,
  Button,
  Collapse,
  useColorModeValue,
} from "@chakra-ui/react";
import { EmailIcon, PhoneIcon } from "@chakra-ui/icons";

const Logo = (props) => {
  return (
    <>
      <Text fontWeight={"700"} color={"white"}>
        <EmailIcon style={{ marginRight: 5, color: "white" }} />
        Email :
        <a
          href="mailto:office@m2hse.co.uk"
          style={{ color: "white", paddingLeft: 8 }}>
          office@m2hse.co.uk
        </a>
      </Text>
      <Text fontWeight={"700"} color={"white"}>
        <PhoneIcon style={{ marginRight: 5, color: "white" }} />
        Telephone :
        <a href="tel:+4402080599944" style={{ color: "white", paddingLeft: 8 }}>
          02080 599944
        </a>
      </Text>
    </>
  );
};

export default function TopBar() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <Box
      position={"sticky"}
      top={0}
      zIndex={"9999"}
      overflow={"hidden"}
      bg={"#941C1C"}
      color={useColorModeValue("black")}
      paddingTop={"5px"}
      height={7}>
      <Container
        height={5}
        as={Stack}
        maxW={"6xl"}
        direction={{ base: "column", md: "row" }}
        spacing={4}
        justify={{ base: "center", md: "space-between" }}
        align={{ base: "center", md: "center" }}>
        <Logo />

        {/* Mobile menu button */}
        <Button
          display={{ base: "block", md: "none" }}
          onClick={toggleMobileMenu}>
          Menu
        </Button>
      </Container>
      {/* Mobile menu */}
      <Collapse in={isMobileMenuOpen} animateOpacity>
        <MobileMenu />
      </Collapse>
    </Box>
  );
}

const MobileMenu = () => {
  return (
    <Box
      overflow={"hidden"}
      bg={useColorModeValue("white")}
      boxShadow={
        "rgba(7, 30, 61, 0.17) 0px -23px 25px 0px inset, rgba(7, 30, 61, 0.15) 0px -36px 30px 0px inset, rgba(7, 30, 61, 0.1) 0px -79px 40px 0px inset, rgba(7, 30, 61, 0.06) 0px 2px 1px, rgba(7, 30, 61, 0.09) 0px 4px 2px, rgba(7, 30, 61, 0.09) 0px 8px 4px, rgba(7, 30, 61, 0.09) 0px 16px 8px, rgba(7, 30, 61, 0.09) 0px 32px 16px"
      }
      color={useColorModeValue("white")}
      paddingTop={"5px"}
      height={7}>
      <Container
        height={5}
        as={Stack}
        maxW={"6xl"}
        direction={"column"}
        spacing={2}
        justify={"center"}
        align={"center"}></Container>
    </Box>
  );
};
