import {
  Heading,
  Text,
  VStack,
  Button,
  UnorderedList,
  ListItem,
  Icon,
  Input,
  Textarea,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  useBreakpointValue,
  Flex,
  Image,
} from "@chakra-ui/react";
import { FaShoppingCart } from "react-icons/fa";
import { MdOutlineFileDownload as Download } from "react-icons/md";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { MdChevronRight } from "react-icons/md";
import AOS from "aos";
import ContactForm from "../nvqPages/course-details/contactForm";
import { Link } from "react-router-dom";
import { useCart } from "../cart/addToCart";

function DetailPage9() {
  React.useEffect(() => {
    AOS.init({
      duration: 1200,
      easing: "ease-in-out-back",
    });
  }, []);

  const { addToCart } = useCart();

  const { isPending, error, data } = useQuery(["product"], () => {
    return axios.get(`product/filter`, {
      params: {
        filter: "detailsPage",
        equal: "/detail-page-9",
      },
    });
  });

  const AddToCart = (data) => {
    const { _id, name, price } = data;
    addToCart(
      {
        _id,
        name,
        price,
        vat: data.productCategory.vat,
        quantity: 1,
        type: data.productCategory.type,
      },
      0
    );
  };
  return (
    <Flex
      direction={useBreakpointValue({ base: "column", md: "row" })}
      bg="white"
      p={useBreakpointValue({ base: 2, md: 4 })}
      justify="space-between"
      px={useBreakpointValue({ base: 6, md: "4rem" })}
      maxW="100%"
      gap={useBreakpointValue({ base: 2, md: 5 })}
      mx="auto"
      position="relative"
      minHeight="100vh"
    >
      <VStack
        spacing={4}
        align="stretch"
        w={useBreakpointValue({ base: "100%", md: "55%" })}
        px={useBreakpointValue({ base: 0, md: 10 })}
        my={useBreakpointValue({ base: 4, md: "3rem" })}
      >
        <Breadcrumb
          mb={5}
          fontSize="xs"
          fontWeight={500}
          spacing="4px"
          separator={<MdChevronRight color="gray.500" />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink href="/">Home</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href="/cscs-cards">
              Apply For CSCS Cards
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href="/detail-page-8">Supervisory</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Heading as="h1" size="lg" color="#941C1C" mb={3}>
          Supervisory
        </Heading>

        <Image
          src={require("../../assests/cscsPage/Cards/Supervisory (1).jpg")}
          maxW="80%"
          h="auto"
          mb={4}
        />
        <Flex
          direction={useBreakpointValue({ base: "column", md: "row" })}
          justify="space-between"
          align="center"
          gap={useBreakpointValue({ base: 2, md: 5 })}
        >
          <Button
            onClick={() => {
              AddToCart(data?.data.result[0]);
            }}
            w={useBreakpointValue({ base: "100%", md: "50%" })}
            borderRadius="2rem"
            color="white"
            bg="#941C1C"
            _hover={{ filter: "brightness(105%)" }}
            transition="all ease-in-out 200ms"
          >
            <Icon as={FaShoppingCart} mr={3} />£{data?.data.result[0].price} +
            VAT
          </Button>
        </Flex>
        <VStack mt={6} textAlign="left" justify="start" align="start">
          <Text fontWeight="bold">Experienced Worker:</Text>
          <Text>
            The CSCS Supervisor card is for supervisors and technical workers
            who have achieved a Construction Related Supervisory/Technical NVQ
            <Link to={"/level-three-page"} style={{ color: "blue" }}>
              NVQ Level 3
            </Link>
            or{" "}
            <Link to={"/level-four-page"} style={{ color: "blue" }}>
              NVQ Level 4
            </Link>
          </Text>
          <Text>
            This card is valid for five years, and all applicants must pass the
            Specialists CITB Health, Safety &amp; Environment Test.
          </Text>
          <Text>
            Applicants must also have passed the appropriate level{" "}
            <Link
              to={"/citb-health-and-safety-policy"}
              style={{ color: "blue" }}
            >
              CITB Managers and Professionals Health, Safety and Environment
              Test
            </Link>
            within the last two years.
          </Text>
          <Text fontWeight="bold">
            Why Choose Us for Your CSCS Card Application?
          </Text>
          <UnorderedList>
            <ListItem>
              Our streamlined process ensures you enjoy the benefits without the
              hassle.
            </ListItem>
          </UnorderedList>
          <Text fontWeight="bold">Benefits of Our CSCS Card Service:</Text>
          <UnorderedList>
            <ListItem>
              Effortless Application: We take care of the entire application
              process, allowing you to focus on what you do best – your work.
            </ListItem>
            <ListItem>
              Expert Guidance: Unsure about the right card for your role? Our
              experienced team is here to guide you through the selection
              process.
            </ListItem>
            <ListItem>
              Time-Efficient Processing: No more waiting. We expedite the
              application process to get your card to you as quickly as
              possible.
            </ListItem>
            <ListItem>
              Peace of Mind: Let professionals handle the paperwork and
              documentation, ensuring accuracy and compliance.
            </ListItem>
          </UnorderedList>
          <Text></Text>
          <Text fontWeight="bold">How It Works:</Text>
          <UnorderedList>
            <ListItem>
              Place Your Order: Make a secure online payment or Contact Us if
              you need more information before you pay and leave the rest to us.
            </ListItem>
            <ListItem>
              Provide Information: Our team of experts will call you to get the
              required information for application.
            </ListItem>
            <ListItem>
              Sit Back and Relax: We handle the entire application process, from
              submission to approval.
            </ListItem>
            <ListItem>
              Receive Your Card: Once approved, your CSCS card will be
              dispatched promptly to your designated address.
            </ListItem>
          </UnorderedList>
          <Text>
            For any questions or assistance, contact us – we’re committed to
            making your construction journey smoother and more successful.
          </Text>
        </VStack>
      </VStack>
      <ContactForm />
    </Flex>
  );
}

export default DetailPage9;
