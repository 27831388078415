import React, { useEffect } from "react";
import "aos/dist/aos.css";
import {
  Box,
  Button,
  Image,
  Grid,
  Text,
  Stack,
  Link,
  GridItem,
  VStack,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  useBreakpointValue,
} from "@chakra-ui/react";
import icon1 from "../assests/contactPage/icons/icon1.svg";
import icon2 from "../assests/contactPage/icons/icon2.svg";
import icon3 from "../assests/contactPage/icons/icon3.svg";
import icon4 from "../assests/contactPage/icons/icon4.svg";
import icon5 from "../assests/contactPage/icons/icon5.svg";
import icon6 from "../assests/contactPage/icons/icon6.svg";

import { MdChevronRight } from "react-icons/md";

const AboutUsPage = () => {
  const data = [
    {
      title: "Learn from the Best",
      content:
        "Our M2HSE instructors are skilled professionals who really know their stuff. They're here to teach you the tricks of the trade.",
      icon: icon1,
    },
    {
      title: "Comprehensive Curriculum",
      content:
        "Master essential to advanced construction skills through our hands-on courses designed for real-world readiness.",
      icon: icon2,
    },
    {
      title: "Industry-Relevant Certification",
      content:
        "Earn Recognized Certificates and Personalized Training to Propel Your Construction Career.",
      icon: icon3,
    },
    {
      title: "Advanced Training Facilities",
      content:
        "Learn with the latest tools in a realistic setting, plus get career support after your program ends.",
      icon: icon4,
    },
    {
      title: "Development Support",
      content:
        "Build Your Future with M2HSE: From Skills Training to Job Placement Support in Construction.",
      icon: icon5,
    },
    {
      title: "Flexible Learning Options",
      content:
        "Flexible Construction Training: Online and On-Site Options to Fit Your Life",
      icon: icon6,
    },
  ];

  return (
    <Box width={"100%"} display={"flex"} flexDirection={"column"}>
      <Stack
        direction={useBreakpointValue({ base: "column", md: "row" })}
        w={useBreakpointValue({ base: "100%", md: "75%" })}
        px={useBreakpointValue({ base: 6, md: 0 })}
        mx="auto"
        justify={"space-between"}
        py={useBreakpointValue({ base: 4, md: "3.5rem" })}
        pb={useBreakpointValue({ base: 10, md: 0 })}
        maxW={"150ch"}
        spacing={4}>
        <Box
          w={useBreakpointValue({ base: "100%", md: "40%" })}
          textAlign={useBreakpointValue({ base: "center", md: "left" })}>
          <Breadcrumb
            mb={5}
            fontSize={"xs"}
            fontWeight={600}
            spacing="4px"
            separator={<MdChevronRight color="gray.500" />}>
            <BreadcrumbItem>
              <BreadcrumbLink href="/">Home</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink href="/about-us">About</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <Text fontSize={"3xl"} fontWeight={600} color="#941C1C">
            About Our Company
          </Text>
          <Text lineHeight={"1.3rem"} mt={2} mb={"-1.5rem"}>
            M2HSE is a leading provider of construction training, committing to
            elevating the skills and knowledge of individuals within the sector,
            setting the standard for professionalism and expertise.
          </Text>

          {/* <Link to={"/contact-us"}>
            <Button
              bg={"#941C1C"}
              color={"white"}
              borderRadius={"2rem"}
              _hover={{ filter: "brightness(105%)" }}
              transition={"all ease-in-out 200ms"}
              marginTop={"50px"}>
              CONTACT US
            </Button>
          </Link> */}
        </Box>
        <Image
          w={useBreakpointValue({ base: "100%", md: "35%" })}
          mb={useBreakpointValue({ base: 0, md: 10 })}
          src={require("../assests/contactPage/team.jpg")}
          alt="team"
          borderRadius={"lg"}
        />
      </Stack>

      <Box bg={"#F8F8FB"} pb={useBreakpointValue({ base: 4, md: 10 })} pt={10}>
        <Text
          textAlign={"center"}
          fontSize={"2xs"}
          fontWeight={800}
          textTransform={"uppercase"}
          color={"#941C1C"}>
          Our Values
        </Text>
        <Text textAlign={"center"} fontSize={"2xl"} fontWeight={600} mb={3}>
          Why Choose Us?
        </Text>
        <Text
          textAlign={"center"}
          w={useBreakpointValue({ base: "75%", md: "30%" })}
          mx={"auto"}
          fontSize={"sm"}
          lineHeight={"1.2rem"}>
          Our training has helped many individuals excel in construction
          careers, earning industry respect and adding significant value to
          their credentials.
        </Text>
        <Grid
          templateColumns={useBreakpointValue({
            base: "repeat(1, 1fr)",
            md: "repeat(3, 1fr)",
          })}
          gap={6}
          w="75%"
          mx={"auto"}
          my={8}
          maxW={"150ch"}>
          {data.map(({ title, icon, content }, index) => (
            <GridItem key={index}>
              <VStack
                bg="white"
                p={5}
                textAlign={"left"}
                h="22ch"
                borderRadius={"md"}
                shadow={"0px 0px 4px 0px rgba(0,0,0,0.075)"}
                align={"left"}>
                <Image src={icon} alt={index} w={"2rem"} />
                <Text fontSize={"xl"} fontWeight={600}>
                  {title}
                </Text>
                <Text opacity={"70%"} lineHeight={"1.3rem"}>
                  {content}
                </Text>
              </VStack>
            </GridItem>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default AboutUsPage;
